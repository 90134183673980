import links from "@config/links"
import {
    BottomBarConfig,
    customersConfig,
    defaultConfig,
} from "@components/BottomNavigator/config"

export enum LogLevel {
    None = 0,
    Error = 1,
    Warning = 2,
    Analytics = 3,
    Info = 4,
    Debug = 5,
    Verbose = 6,
}

// backend specific properties
export interface BackendConfigProperties {
    apiUrl: string
    amplifyAuth: {
        region: string
        userPoolId: string
        userPoolWebClientId: string
    }
    awsS3: {
        accessKeyId: string
        secretAccessKey: string
        bucket: string
        region: string
    }
}

// total environment config (including backend and frontend)
export interface EnvironmentConfigProperties extends BackendConfigProperties {
    sentryDebug: boolean
    logLevel: LogLevel
    sentry: {
        dsn: string
    }
    analytics: {
        shouldLog: boolean
    }
}

export interface TenantInfoModalLink {
    title: string
    subtitle: string
    url: string
}

export interface UtmParams {
    source: string
    medium: string
    campaign: string
}
export interface TenantConfigProperties {
    type: "polls" | "customer"
    backendTenantName: string
    analyticsTenantName: string
    name?: string
    subtitle?: string
    homeUrl: string
    infoModalLinks: TenantInfoModalLink[]
    bottomBarConfig: BottomBarConfig
    shouldSupportDynamicTheme: boolean
    utmParams?: UtmParams
}

// list of available backend configs

type BackendConfigs = {
    [key: string]: BackendConfigProperties
}

// list of available environment configs (including backend and frontend)

type EnvironmentConfigs = {
    [key: string]: EnvironmentConfigProperties
}

// list of available tenant configs

type TenantConfigs = {
    [key: string]: TenantConfigProperties
}

// total config

export interface ConfigProperties extends EnvironmentConfigProperties {
    tenantConfig: TenantConfigProperties
}

// lists of configurations below

const backendConfigs: BackendConfigs = {
    dev: {
        apiUrl: "https://dev.polls-api.com/v1/",
        amplifyAuth: {
            region: "us-east-1",
            userPoolId: "us-east-1_K4eyhmPwT",
            userPoolWebClientId: "6runbqsvf8v8b2c56kcivsp4ar",
        },
        awsS3: {
            accessKeyId: "AKIAVPQP3SY7462OADDI",
            secretAccessKey: "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",
            bucket: "dev1-poll-link-previews-bucket",
            region: "us-east-1",
        },
    },
    staging: {
        apiUrl: "https://staging.polls-api.com/v1/",
        amplifyAuth: {
            region: "us-east-1",
            userPoolId: "us-east-1_hR0QurhJk",
            userPoolWebClientId: "144djebf1e3ja5if38bg27tk5r",
        },
        awsS3: {
            accessKeyId: "AKIAVPQP3SY7RUAQMKIJ",
            secretAccessKey: "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",
            bucket: "staging-poll-link-previews-bucket",
            region: "us-east-1",
        },
    },
    uat: {
        apiUrl: "https://uat.polls-api.com/v1/",
        amplifyAuth: {
            region: "us-east-1",
            userPoolId: "us-east-1_VZ10CFr2B",
            userPoolWebClientId: "4ho5psgti6duksass89ufqil1p",
        },
        awsS3: {
            accessKeyId: "AKIAVPQP3SY7RABRCCUF",
            secretAccessKey: "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",
            bucket: "uat-poll-link-previews-bucket",
            region: "us-east-1",
        },
    },
    int: {
        apiUrl: "https://int.polls-api.com/v1/",
        amplifyAuth: {
            region: "us-east-1",
            userPoolId: "us-east-1_6DfuGOHvv",
            userPoolWebClientId: "5n7vq38339i1e9oe4jtimuqqpd",
        },
        awsS3: {
            accessKeyId: "AKIAVPQP3SY7YWLPRLQG",
            secretAccessKey: "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",
            bucket: "int-poll-link-previews-bucket",
            region: "us-east-1",
        },
    },
    prod: {
        apiUrl: "https://polls-api.com/v1/",
        amplifyAuth: {
            region: "us-east-1",
            userPoolId: "us-east-1_cqIuFmogq",
            userPoolWebClientId: "rust6doj2psrfv31grhlaalbl",
        },
        awsS3: {
            accessKeyId: "AKIAVPQP3SY7W2AXVINC",
            secretAccessKey: "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",
            bucket: "prod-poll-link-previews-bucket",
            region: "us-east-1",
        },
    },
}

export const environmentConfigs: EnvironmentConfigs = {
    devBackend: {
        // this env uses dev backend which is unstable, this env is used to test during backend dev
        ...backendConfigs.dev,
        sentryDebug: true,
        logLevel: LogLevel.Verbose,
        sentry: {
            dsn: "https://156148a641d04a688d84f51a676bf6ae@o1070030.ingest.sentry.io/6075458",
        },
        analytics: {
            shouldLog: false,
        },
    },
    dev: {
        // this env uses the prod backend so frontend devs get a more stable backend
        ...backendConfigs.staging,
        sentryDebug: true,
        logLevel: LogLevel.Verbose,
        sentry: {
            dsn: "https://156148a641d04a688d84f51a676bf6ae@o1070030.ingest.sentry.io/6075458",
        },
        analytics: {
            shouldLog: false,
        },
    },
    staging: {
        ...backendConfigs.staging,
        sentryDebug: true,
        logLevel: LogLevel.Debug,
        sentry: {
            dsn: "https://156148a641d04a688d84f51a676bf6ae@o1070030.ingest.sentry.io/6075458",
        },
        analytics: {
            shouldLog: false,
        },
    },
    uat: {
        ...backendConfigs.uat,
        sentryDebug: true,
        logLevel: LogLevel.Debug,
        sentry: {
            dsn: "https://156148a641d04a688d84f51a676bf6ae@o1070030.ingest.sentry.io/6075458",
        },
        analytics: {
            shouldLog: true,
        },
    },
    int: {
        ...backendConfigs.int,
        sentryDebug: false,
        logLevel: LogLevel.None,
        sentry: {
            dsn: "https://156148a641d04a688d84f51a676bf6ae@o1070030.ingest.sentry.io/6075458",
        },
        analytics: {
            shouldLog: true,
        },
    },
    prod: {
        ...backendConfigs.prod,
        sentryDebug: false,
        logLevel: LogLevel.None,
        sentry: {
            dsn: "https://156148a641d04a688d84f51a676bf6ae@o1070030.ingest.sentry.io/6075458",
        },
        analytics: {
            shouldLog: true,
        },
    },
}

const defaultCustomerConfig: Omit<
    TenantConfigProperties,
    "backendTenantName" | "analyticsTenantName" | "homeUrl" | "infoModalLinks"
> = {
    type: "customer",
    bottomBarConfig: customersConfig,
    shouldSupportDynamicTheme: false,
}

export const tenantConfigs: TenantConfigs = {
    polls: {
        backendTenantName: "polls",
        analyticsTenantName: "polls",
        type: "polls",
        name: "Polls Platform",
        homeUrl: links.pollsUrl,
        subtitle: "Group decisions made easy",
        infoModalLinks: [],
        bottomBarConfig: defaultConfig,
        shouldSupportDynamicTheme: false,
    },
    demo: {
        ...defaultCustomerConfig,
        backendTenantName: "demo",
        analyticsTenantName: "demo",
        homeUrl: links.pollsUrl,
        shouldSupportDynamicTheme: true,
        infoModalLinks: [
            {
                title: "How to Create Polls",
                subtitle: "Learn how to send your own polls",
                url: "https://pollsplatform.com",
            },
            {
                title: "Help Center",
                subtitle: "Need help? We've got your back",
                url: "https://pollsplatform.com",
            },
        ],
    },
    pinktada: {
        ...defaultCustomerConfig,
        backendTenantName: "pinktada",
        analyticsTenantName: "pinktada",
        homeUrl: "https://www.pinktada.com/",
        infoModalLinks: [
            {
                title: "Join Pinktada",
                subtitle: "Experience travel like never before",
                url: "https://www.pinktada.com/",
            },
        ],
    },
    wuwwuw: {
        ...defaultCustomerConfig,
        backendTenantName: "wuwwuw",
        analyticsTenantName: "wuwwuw",
        homeUrl: "https://www.wuwwuw.com/",
        infoModalLinks: [
            {
                title: "Join Wuw Wuw",
                subtitle: "Get what you want, when you want",
                url: "https://www.wuwwuw.com/",
            },
        ],
    },
    propswap: {
        ...defaultCustomerConfig,
        backendTenantName: "propswap",
        analyticsTenantName: "propswap",
        homeUrl: "https://propswap.com/",
        infoModalLinks: [
            {
                title: "Join PropSwap",
                subtitle: "Find the best sportsbook tickets for sale.",
                url: "https://propswap.com/account/about",
            },
        ],
    },
    coordle: {
        ...defaultCustomerConfig,
        backendTenantName: "coordle",
        analyticsTenantName: "coordle",
        homeUrl: "https://coordleapp.com/",
        infoModalLinks: [
            {
                title: "Join Coordle",
                subtitle: "Experience Better Travel With Coordle",
                url: "https://coordleapp.com/",
            },
        ],
    },
    "12thtribe": {
        ...defaultCustomerConfig,
        backendTenantName: "12thtribe",
        analyticsTenantName: "12thtribe",
        homeUrl: "https://www.12thtribe.com/",
        infoModalLinks: [
            {
                title: "Join the Tribe",
                subtitle: "Globally inspired jewelry and clothing.",
                url: "https://www.12thtribe.com/",
            },
        ],
        utmParams: {
            source: "polls",
            medium: "referral",
            campaign: "share",
        },
    },
    ticketfairy: {
        ...defaultCustomerConfig,
        backendTenantName: "ticketfairy",
        analyticsTenantName: "ticketfairy",
        homeUrl: "https://www.ticketfairy.com/",
        infoModalLinks: [
            {
                title: "Join The Ticket Fairy",
                subtitle:
                    "LIVE FOR LIVE",
                url: "https://www.ticketfairy.com/",
            },
        ],
    },
    fuego: {
        ...defaultCustomerConfig,
        backendTenantName: "fuego",
        analyticsTenantName: "fuego",
        homeUrl: "https://fuegodance.com/",
        infoModalLinks: [
            {
                title: "Shop Fuego",
                subtitle: "The World's Best Dance Sneakers",
                url: "https://fuegodance.com/",
            },
        ],
        utmParams: {
            source: "polls",
            medium: "referral",
            campaign: "share",
        },
    },
    vote: {
        ...defaultCustomerConfig,
        backendTenantName: "vote",
        analyticsTenantName: "vote",
        homeUrl: "https://pollsplatform.com",
        infoModalLinks: [
            {
                title: "Get Polls",
                subtitle: "Add polls to your store in just 5m",
                url: "https://pollsplatform.com",
            },
        ],
        utmParams: {
            source: "polls",
            medium: "referral",
            campaign: "share",
        },
    },
}

export const config = (options: {
    env: string
    tenant: string
    isQaTenant: boolean
}): ConfigProperties => {
    const { env, tenant, isQaTenant } = options

    const environmentConfig: EnvironmentConfigProperties =
        environmentConfigs[env] ?? environmentConfigs.dev

    const foundTenantConfig: TenantConfigProperties | undefined =
        tenantConfigs[tenant]

    if (foundTenantConfig && isQaTenant) {
        const qaTenantSuffix = "-qa"
        // if we are a tenant qa, we will use the same config but update the backend tenant name to use QA suffix
        const qaTenantName = `${tenant}${qaTenantSuffix}`
        foundTenantConfig.backendTenantName = qaTenantName
        foundTenantConfig.analyticsTenantName = qaTenantName
    }

    const tenantConfig: TenantConfigProperties =
        foundTenantConfig ?? tenantConfigs.polls

    return {
        ...environmentConfig,
        tenantConfig: tenantConfig,
    }
}
