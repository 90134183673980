import {
    LegacyPostVoteAction,
    PollData,
    PollHistoryItem,
    PollMutableSettings,
    PollOption,
    PollOptionLink,
    PollOptionType,
    PollOptionValueType,
    PollSettings,
    PostVoteActionType,
} from "@types"
import React, { Dispatch, Reducer } from "react"
import { LogEntry } from "@services/poll/updatePoll"
import uuid from "react-native-uuid"
import Logger from "@services/logger"
import { AnalyticsEvent, logEvent, logEventWithPoll } from "@services/analytics"
import { getRequestPollOptions } from "@helpers/getRequestPoll"

export enum PollActionType {
    SET_DATA = "setData",
    SET_DATA_FROM_DRAFT = "setDataFromDraft",
    SET_POLL_SETTINGS = "setPollSettings",
    SET_DATA_OPTIONS = "setDataOptions",
    RESET_POLL = "resetPoll",
    SET_POLL_TITLE = "setPollTitle",
    SET_POLL_TITLE_FROM_PARAMS = "setPollTitleFromParams",
    SET_POLL_TITLE_EDIT = "setPollTitleEdit",
    CHANGE_POLL_TITLE = "changePollTitle",
    SET_POLL_ID = "setPollId",
    SET_EDIT_MODE = "setEditMode",
    EXIT_EDIT_MODE = "exitEditMode",
    SET_EDIT_VOTE_MODE = "setEditVoteMode",
    SET_UPDATE_SUCCESS = "setUpdateSuccess",
    SET_LAUNCHED = "setLaunched",
    SET_ERROR_MESSAGE = "setErrorMessage",
    REFRESH_POLL_DATA = "refreshPollData",
    POLL_DATA_REFRESHED = "pollDataRefreshed",
    TOGGLE_POLL_SETTING = "togglePollSetting",
    UPDATE_POLL_DATA = "updatePollData",
    POLL_DATA_UPDATED = "pollDataUpdated",
    LAUNCH_POLL = "launchPoll",
    LAUNCH_POLL_DATA_READY = "launchPollDataReady",
    LAUNCH_DONE = "launchDone",
    SET_CREATE_DRAFT_MODE = "setCreateDraftMode",
    UNSET_CREATE_DRAFT_MODE = "unsetCreateDraftMode",
    CREATE_PREVIEW_IMAGE = "createPreviewImage",
    PREVIEW_IMAGE_CREATED = "previewImageCreated",
    ADD_OPTION = "addOption",
    REMOVE_OPTION = "removeOption",
    SET_OPTIONS = "setOptions",
    SET_OPTION_TEXT = "setOptionText",
    STORE_DATA = "storeData",
    DATA_STORED = "dataStored",
    SET_SCROLL_TOP = "setScrollTop",
    VOTE = "vote",
    REFRESH_POLL_HISTORY = "refreshPolHistory",
    POLL_HISTORY_REFRESHED = "pollHistoryRefreshed",
    FILTER_EMPTY_POLL_OPTIONS = "filterEmptyPollOptions",
    NEED_UPDATING = "needUpdating",
    PUBLISH_POLL = "publishPoll",
    PUBLISH_POLL_READY = "publishPollReady",
    POLL_PUBLISHED = "pollPublished",
    TOGGLE_HINT_MODAL = "toggleHintModal",
    SET_POLL_PENDING = "setPollPending",
    LINK_OPTION_PARSED = "linkOptionParsed",
    SET_CUSTOMER_SHARE_POLL = "setCustomerSharePoll",
}

export type Action =
    | {
          type: PollActionType.SET_DATA
          payload: { data: PollData; pollTitle?: string }
      }
    | {
          type: PollActionType.SET_DATA_FROM_DRAFT
          payload: {
              options: PollOption[]
              pollTitle: string
              settings?: PollSettings
          }
      }
    | {
          type: PollActionType.SET_POLL_SETTINGS
          payload: { settings: PollSettings }
      }
    | { type: PollActionType.SET_DATA_OPTIONS; payload: PollOption[] }
    | { type: PollActionType.RESET_POLL }
    | { type: PollActionType.CHANGE_POLL_TITLE; payload: { title: string } }
    | {
          type: PollActionType.SET_POLL_TITLE_FROM_PARAMS
          payload: { title: string }
      }
    | { type: PollActionType.SET_POLL_TITLE }
    | { type: PollActionType.SET_POLL_TITLE_EDIT; payload: boolean }
    | { type: PollActionType.SET_POLL_ID; payload: string }
    | { type: PollActionType.SET_EDIT_MODE; payload: { enabled: boolean } }
    | { type: PollActionType.EXIT_EDIT_MODE }
    | { type: PollActionType.SET_EDIT_VOTE_MODE; payload: { enabled: boolean } }
    | { type: PollActionType.SET_UPDATE_SUCCESS; payload: boolean }
    | { type: PollActionType.SET_LAUNCHED; payload: { poll: PollData } }
    | { type: PollActionType.REFRESH_POLL_DATA; screenShotOnRefresh?: boolean }
    | { type: PollActionType.POLL_DATA_REFRESHED }
    | { type: PollActionType.TOGGLE_POLL_SETTING; payload: PollMutableSettings }
    | { type: PollActionType.UPDATE_POLL_DATA }
    | { type: PollActionType.POLL_DATA_UPDATED }
    | { type: PollActionType.LAUNCH_POLL }
    | { type: PollActionType.LAUNCH_POLL_DATA_READY }
    | { type: PollActionType.LAUNCH_DONE }
    | { type: PollActionType.SET_CREATE_DRAFT_MODE }
    | { type: PollActionType.UNSET_CREATE_DRAFT_MODE }
    | { type: PollActionType.CREATE_PREVIEW_IMAGE }
    | { type: PollActionType.PREVIEW_IMAGE_CREATED }
    | {
          type: PollActionType.ADD_OPTION
          payload: {
              ownerId: string
              type: PollOptionType
              valueType: PollOptionValueType
              editVoteMode: boolean
          }
      }
    | {
          type: PollActionType.REMOVE_OPTION
          payload: { id: string }
      }
    | { type: PollActionType.SET_OPTIONS; payload: { options: PollOption[] } }
    | {
          type: PollActionType.SET_OPTION_TEXT
          payload: { text: string; id: string }
      }
    | { type: PollActionType.STORE_DATA }
    | { type: PollActionType.DATA_STORED }
    | { type: PollActionType.SET_SCROLL_TOP; payload: boolean }
    | {
          type: PollActionType.VOTE
          payload: {
              id: string
              valueType: PollOptionValueType
              userId: string
          }
      }
    | { type: PollActionType.FILTER_EMPTY_POLL_OPTIONS }
    | { type: PollActionType.REFRESH_POLL_HISTORY }
    | {
          type: PollActionType.POLL_HISTORY_REFRESHED
          payload: PollHistoryItem[]
      }
    | {
          type: PollActionType.SET_ERROR_MESSAGE
          payload: { message: string }
      }
    | {
          type: PollActionType.NEED_UPDATING
          payload: { needUpdating: boolean }
      }
    | { type: PollActionType.PUBLISH_POLL; payload: { options: PollOption[] } }
    | { type: PollActionType.PUBLISH_POLL_READY }
    | { type: PollActionType.POLL_PUBLISHED }
    | { type: PollActionType.TOGGLE_HINT_MODAL; payload: { visible: boolean } }
    | { type: PollActionType.SET_POLL_PENDING }
    | {
          type: PollActionType.LINK_OPTION_PARSED
          payload: { option: PollOptionLink }
      }
    | { type: PollActionType.SET_CUSTOMER_SHARE_POLL; payload: boolean }

interface PollState {
    data: PollData
    votesToCompare: Vote[]
    currentSessionVotesByUser: Vote[]
    sequentialUpdates: LogEntry[]
    optionsToPublish: PollOption[]
    optionsAdded: boolean
    needUpdating: boolean
    pollHistory: PollHistoryItem[]
    pollId: string
    pollTitle: string
    fromParams: boolean
    pollTitleEdit: boolean
    isTitleSet: boolean
    loading: boolean
    updating: boolean
    editMode: boolean
    exitEditMode: boolean
    editVoteMode: boolean
    isCreateDraftMode: boolean
    isLaunched: boolean
    isDataRestored: boolean
    storeData: boolean
    refreshPollData: boolean
    screenshotOnRefresh: boolean
    updatePollData: boolean
    launchPoll: boolean
    createPreviewImage: boolean
    isScrollTop: boolean
    refreshPollHistory: boolean
    errorMessage: string
    openSharePoll: boolean
    originalOptionsLength: number
    pollPending: boolean
    isHintVisible: boolean
    customerSharePoll: boolean
}

export type Vote = {
    optionId: string
    value: string
}

interface PollContextContent {
    pollState: PollState
    pollDispatch: PollAction
}

type PollAction = Dispatch<Action>

const initialState: PollState = {
    pollId: "",
    pollTitle: "",
    fromParams: false,
    pollTitleEdit: false,
    isTitleSet: false,
    data: {
        id: "",
        ownerId: "",
        title: "",
        url: "",
        state: undefined,
        shareLink: "",
        shareLinkTemplate: "",
        shareLinkTemplateWebp: "",
        shareLinkTemplateJpeg: "",
        version: "",
        minCompatibleVersion: "",
        timeZoneId: "",
        options: [],
        settings: {
            editPoll: true,
            seeWhoVoted: true,
            multipleVotes: true,
            postVoteAction: LegacyPostVoteAction.Values.none,
            postVoteActionMap: {
                default: {
                    type: PostVoteActionType.Values.none,
                },
            },
        },
        totalVoteCount: 0,
        previewImageFilename: "",
        previewImageFileUuid: "",
    },
    votesToCompare: [],
    currentSessionVotesByUser: [],
    pollHistory: [],
    sequentialUpdates: [],
    optionsToPublish: [],
    optionsAdded: false,
    loading: true,
    updating: false,
    editMode: false,
    exitEditMode: false,
    editVoteMode: false,
    isCreateDraftMode: false,
    isLaunched: false,
    isDataRestored: false,
    storeData: false,
    needUpdating: false,
    refreshPollData: false,
    screenshotOnRefresh: false,
    updatePollData: false,
    launchPoll: false,
    createPreviewImage: false,
    isScrollTop: true,
    refreshPollHistory: false,
    errorMessage: "",
    originalOptionsLength: 0,
    openSharePoll: false,
    pollPending: false,
    isHintVisible: false,
    customerSharePoll: false,
}

const PollContext = React.createContext<PollContextContent>({
    pollState: initialState,
    pollDispatch: () => initialState,
})

const PollReducer: Reducer<PollState, Action> = (
    state: PollState,
    action: Action,
) => {
    Logger.info("ACTION PollReducer:" + action.type)

    switch (action.type) {
        case PollActionType.SET_DATA: {
            const { data, pollTitle } = action.payload
            const votesToCompare: Vote[] = []
            data.options.map(option =>
                option.votesByCurrentUser.map(vote =>
                    votesToCompare.push({
                        optionId: vote.optionId,
                        value: vote.value,
                    }),
                ),
            )

            return {
                ...state,
                data,
                pollTitle: pollTitle ? pollTitle : state.pollTitle,
                votesToCompare,
                currentSessionVotesByUser: votesToCompare,
                originalOptionsLength: data.options.length,
                sequentialUpdates: [],
                optionsAdded: false,
            }
        }
        case PollActionType.SET_DATA_FROM_DRAFT: {
            const { options, pollTitle, settings } = action.payload
            return {
                ...state,
                data: {
                    ...state.data,
                    options,
                    settings: settings ?? initialState.data.settings,
                },
                pollTitle,
                isTitleSet: !!pollTitle?.length,
                isDataRestored: true,
            }
        }
        case PollActionType.SET_POLL_SETTINGS: {
            const { settings } = action.payload
            return {
                ...state,
                data: {
                    ...state.data,
                    settings,
                },
            }
        }
        case PollActionType.SET_DATA_OPTIONS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    options: action.payload,
                },
            }
        }
        case PollActionType.RESET_POLL: {
            return {
                ...state,
                data: initialState.data,
                pollTitle: "",
                isTitleSet: false,
                votesToCompare: [],
                currentSessionVotesByUser: [],
            }
        }
        case PollActionType.SET_POLL_ID: {
            const pollId = action.payload
            return { ...state, pollId, refreshPollData: true, loading: true }
        }
        case PollActionType.SET_POLL_TITLE: {
            return {
                ...state,
                isTitleSet: true,
                pollTitleEdit: false,
                fromParams: false,
            }
        }
        case PollActionType.SET_POLL_TITLE_FROM_PARAMS: {
            const { title } = action.payload
            return {
                ...state,
                pollTitle: title,
                fromParams: true,
            }
        }
        case PollActionType.SET_POLL_TITLE_EDIT: {
            if (state.isTitleSet) logEvent(AnalyticsEvent.tapDraftEditTitle)

            return { ...state, pollTitleEdit: action.payload }
        }
        case PollActionType.CHANGE_POLL_TITLE: {
            const { title } = action.payload
            return { ...state, pollTitle: title }
        }
        case PollActionType.SET_EDIT_MODE: {
            const { enabled } = action.payload
            return { ...state, editMode: enabled, exitEditMode: false }
        }
        case PollActionType.EXIT_EDIT_MODE: {
            return { ...state, exitEditMode: true }
        }
        case PollActionType.SET_EDIT_VOTE_MODE: {
            const { enabled } = action.payload
            return { ...state, editVoteMode: enabled, exitEditMode: false }
        }
        case PollActionType.SET_UPDATE_SUCCESS: {
            const updateSuccess = action.payload
            return { ...state, updateSuccess }
        }
        case PollActionType.SET_LAUNCHED: {
            const { poll } = action.payload
            logEventWithPoll(AnalyticsEvent.createPollSuccess, state.data)
            return {
                ...state,
                data: poll,
                isLaunched: true,
                refreshPollData: false,
                isCreateDraftMode: false,
                title: "",
            }
        }
        case PollActionType.REFRESH_POLL_DATA: {
            return {
                ...state,
                refreshPollData: true,
                screenshotOnRefresh: !!action.screenShotOnRefresh,
            }
        }
        case PollActionType.POLL_DATA_REFRESHED: {
            return {
                ...state,
                refreshPollData: false,
                loading: false,
                screenshotOnRefresh: false,
            }
        }
        case PollActionType.TOGGLE_POLL_SETTING: {
            const pollData = { ...state.data }

            if (pollData.settings)
                pollData.settings[action.payload] =
                    !pollData.settings[action.payload]

            return { ...state, data: pollData }
        }
        case PollActionType.UPDATE_POLL_DATA: {
            return { ...state, updating: true, updatePollData: true }
        }
        case PollActionType.POLL_DATA_UPDATED: {
            return { ...state, updating: false, updatePollData: false }
        }
        case PollActionType.LAUNCH_POLL: {
            return { ...state, launchPoll: true, loading: true }
        }
        case PollActionType.LAUNCH_POLL_DATA_READY: {
            return {
                ...state,
                launchPoll: false,
                loading: false,
                editMode: false,
            }
        }
        case PollActionType.LAUNCH_DONE: {
            return {
                ...state,
                isLaunched: false,
                openSharePoll: false,
                optionsToPublish: initialState.optionsToPublish,
            }
        }
        case PollActionType.SET_CREATE_DRAFT_MODE: {
            return {
                ...state,
                data: initialState.data,
                isCreateDraftMode: true,
                pollId: "",
                isDataRestored: false,
                storeData: false,
                loading: false,
                pollTitleEdit: false,
                pollTitle: initialState.pollTitle,
            }
        }
        case PollActionType.UNSET_CREATE_DRAFT_MODE: {
            return {
                ...state,
                isCreateDraftMode: false,
                isDataRestored: false,
                data: initialState.data,
                storeData: false,
                editMode: false,
                pollTitleEdit: false,
                pollTitle: initialState.pollTitle,
            }
        }
        case PollActionType.CREATE_PREVIEW_IMAGE: {
            return { ...state, createPreviewImage: true }
        }
        case PollActionType.PREVIEW_IMAGE_CREATED: {
            return { ...state, createPreviewImage: false }
        }
        case PollActionType.ADD_OPTION: {
            const { ownerId, type, valueType, editVoteMode } = action.payload
            const updatedOptions = [...state.data.options]
            const id = String(uuid.v4())

            if (type !== "text") return { ...state }

            updatedOptions.push({
                type,
                id,
                title: "",
                ownerId: ownerId ? ownerId : state.data.ownerId,
                recentVotes: [],
                votesByCurrentUser: [],
                voteCount: [{ count: 0, value: valueType }],
            })

            const sequentialUpdates = [...state.sequentialUpdates]
            if (!state.isCreateDraftMode) {
                sequentialUpdates.push({
                    type: "addOption",
                    option: {
                        id,
                        title: "",
                        type,
                    },
                })
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    options: updatedOptions,
                },
                optionsAdded: true,
                storeData: true,
                sequentialUpdates,
                editVoteMode,
            }
        }

        case PollActionType.REMOVE_OPTION: {
            const { id } = action.payload

            const sequentialUpdates = [...state.sequentialUpdates]

            if (!state.isCreateDraftMode) {
                sequentialUpdates.push({
                    type: "removeOption",
                    optionId: id,
                })
            }

            return {
                ...state,
                sequentialUpdates,
            }
        }
        case PollActionType.SET_OPTIONS: {
            const { options } = action.payload
            return {
                ...state,
                data: {
                    ...state.data,
                    options,
                },
                storeData: true,
            }
        }
        case PollActionType.SET_OPTION_TEXT: {
            const { text, id } = action.payload
            const updatedOptions = [...state.data.options]
            updatedOptions.map(o => {
                if (o.id === id) o.title = text
            })
            const sequentialUpdates = [...state.sequentialUpdates]
            sequentialUpdates.map(log => {
                if (log.type === "addOption" && log.option.id === id)
                    log.option.title = text
            })

            return {
                ...state,
                data: {
                    ...state.data,
                    options: updatedOptions,
                },
                sequentialUpdates,
                storeData: true,
            }
        }
        case PollActionType.STORE_DATA: {
            return {
                ...state,
                storeData: true,
            }
        }
        case PollActionType.DATA_STORED: {
            return {
                ...state,
                storeData: false,
            }
        }
        case PollActionType.SET_SCROLL_TOP: {
            return {
                ...state,
                isScrollTop: action.payload,
            }
        }
        case PollActionType.VOTE: {
            const { id, valueType, userId } = action.payload
            const updatedOptions = [...state.data.options]
            let votes = [...state.currentSessionVotesByUser]
            let total = state.data.totalVoteCount

            const removeVote = (option: PollOption) => {
                votes = votes.filter(vote => vote.optionId !== option.id)

                const count = option.voteCount[0]?.count || 0
                option.voteCount = [{ count: count - 1, value: "true" }]
                total -= 1
                option.recentVotes = option.recentVotes.filter(
                    v => v.voter.id !== userId,
                )
            }

            const addVote = (option: PollOption) => {
                logEventWithPoll(AnalyticsEvent.tapVoteSelectOption, state.data)

                votes.push({ optionId: id, value: valueType })

                const count = option.voteCount[0]?.count || 0
                option.voteCount = [{ count: count + 1, value: "true" }]
                total += 1
                option.recentVotes = [
                    ...option.recentVotes,
                    {
                        id: "",
                        optionId: option.id,
                        createdAt: "",
                        voter: {
                            name: "",
                            id: userId,
                        },
                    },
                ]
            }

            updatedOptions.map(option => {
                const hasVoted = votes.some(vote => vote.optionId === option.id)

                if (option.id === id) {
                    if (hasVoted) {
                        logEventWithPoll(
                            AnalyticsEvent.tapVoteUnselectOption,
                            state.data,
                        )

                        removeVote(option)
                    } else {
                        addVote(option)
                    }
                } else {
                    if (!state.data.settings.multipleVotes && hasVoted) {
                        removeVote(option)
                    }
                }
            })

            return {
                ...state,
                currentSessionVotesByUser: votes,
                data: {
                    ...state.data,
                    options: updatedOptions,
                    totalVoteCount: total,
                },
            }
        }
        case PollActionType.REFRESH_POLL_HISTORY: {
            return { ...state, refreshPollHistory: true }
        }
        case PollActionType.POLL_HISTORY_REFRESHED: {
            const pollHistory = action.payload

            return { ...state, pollHistory, refreshPollHistory: false }
        }
        case PollActionType.FILTER_EMPTY_POLL_OPTIONS: {
            const updatedOptions = state.data.options.filter(
                option => option.title.length > 0,
            )

            return {
                ...state,
                data: {
                    ...state.data,
                    options: updatedOptions,
                },
                storeData: true,
            }
        }
        case PollActionType.SET_ERROR_MESSAGE: {
            const { message } = action.payload

            return { ...state, errorMessage: message }
        }
        case PollActionType.NEED_UPDATING: {
            const { needUpdating } = action.payload

            return { ...state, needUpdating }
        }
        case PollActionType.PUBLISH_POLL: {
            const { options } = action.payload

            return { ...state, optionsToPublish: options, updating: true }
        }
        case PollActionType.POLL_PUBLISHED: {
            return { ...state, updating: false }
        }
        case PollActionType.TOGGLE_HINT_MODAL: {
            const { visible } = action.payload
            return { ...state, isHintVisible: visible }
        }
        case PollActionType.SET_POLL_PENDING: {
            return { ...state, pollPending: true }
        }
        case PollActionType.LINK_OPTION_PARSED: {
            const { option } = action.payload
            const sequentialUpdates = [...state.sequentialUpdates]

            const optionIndex = sequentialUpdates.findIndex(item => {
                if (item && item.type === "addOption") {
                    return item.option.id === option.id
                }
            })

            if (optionIndex < 0) return { ...state }

            const item = sequentialUpdates[optionIndex]

            if (item.type === "addOption") {
                item.option = getRequestPollOptions([option])[0]

                sequentialUpdates[optionIndex] = item
            }

            return { ...state, sequentialUpdates: sequentialUpdates }
        }
        case PollActionType.SET_CUSTOMER_SHARE_POLL: {
            return { ...state, customerSharePoll: action.payload }
        }
        default:
            return state
    }
}

export {
    PollAction,
    PollContext,
    PollReducer as pollReducer,
    PollState,
    initialState,
}
