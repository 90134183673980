import { ImageSourcePropType } from "react-native"
import { Icons } from "@assets/icons"
import { EncodedImages } from "@assets/images/encodedImages"
import { FontSource } from "expo-font"

export type base64Image = string

/* eslint-disable no-undef */
interface AvailableColors {
    // App // items used throughout the app globally
    skeletonDark: string
    skeletonLight: string
    skeletonHighlight: string
    appBackgroundMobile: string
    appBackgroundDesktop: string
    appBackgroundCardDesktop: string
    modalBackground: string
    modalOverlay: string
    modalDivider: string
    divider: string
    checkBox: string
    navHeaderBackground: string

    // input in box variant
    inputBoxColor: string
    inputBoxBorderColor: string
    inputPlaceholderColor: string

    // Bottom Navigation // notification for the user after they have selected an option or made an update
    bottomNavigationBackgroundColor: string
    bottomNavigationFlagBackground: string

    // Poll Title //
    titleContainer: string
    pollTitleCursor: string
    pollTitleDivider: string

    // Help Text // this is floating text on the set poll title screen and add options screens
    helpTextPrimary: string
    helpTextSecondary: string

    // CTAs // the primary tappable buttons and different states of each
    ctaPrimaryInactiveBackground: string // e.g. submit button when disabled
    ctaPrimaryActiveBackground: string // e.g. submit button when enabled
    ctaPrimarySuccessBackground: string // e.g. after copying link on share poll screen
    ctaPrimaryText: string // e.g. submit button title
    ctaPrimaryDisabledText: string // e.g. submit button title
    ctaSecondaryInactiveBackground: string // e.g. add option button
    ctaSecondaryActiveBackground: string // e.g. add option button
    ctaSecondaryText: string // e.g. add option button title
    ctaSecondaryDisabledText: string // e.g. add option button title
    ctaSecondaryTextFailure: string // e.g. error messages
    ctaFloatingText: string // e.g. Cancel button when editing user name in profile
    ctaFloatingDisabledText: string // e.g. Cancel button when editing user name in profile
    ctaDelete: string // e.g. Remove/Delete button on bottom navbar

    // Option Cards // Option cards both in draft and active states
    optionCardBackground: string
    optionCardTextCursor: string
    optionCardDateTimeText: string // second line of text for date time autocomplete cards
    optionCardProgressBarBackground: string
    optionCardProgressBarFill: string
    optionCardLinkText: string // domain text color e.g. airbnb.com
    optionCardLockedIcon: string
    optionCardRemoveIcon: string
    optionCardDeleteIcon: string
    optionCardGroupIcon: string
    optionCardCheckbox: string
    optionCardSkeletonBackground: string

    // Profile Page //
    profileAvatarBackground: string // set

    // Profile Page User Info // User specific info on the profile page
    profileUpdateNameTextCursor: string

    // Poll Default Settings //
    profileSettingsCardBackground: string

    // Drawers/Modal //
    notificationPollUrlCardBackground: string // e.g. Card for Poll URL to copy in share drawer

    // Customer info modal
    customerInfoModalDivider: string
    customerInfoModalItemIcon: string
    customerInfoModalItemIconContainer: string

    // Poll Send Settings // Individual controls before the poll creator sends the poll
    pollSettingCard: string
    pollSettingText: string

    pollOverlay: string

    // Share Buttons //
    shareViaAppButtonBackgroundColor: string // circle with app icon it in for sharing via SMS, email, WhatsApp, Facebook, etc.
    shareViaAppButtonText: string // text below which says app name for sharing via SMS, email, WhatsApp, Facebook, etc.
    shareQrCodeBackground: string
    shareQrCodeForeground: string

    // Verify Phone Number // Flow for users to enter phone numbers and verification codes to sign in
    phoneNumberCardBackground: string
    phoneNumberCardTextPlaceHolder: string
    phoneNumberCardTextActive: string
    verificationCodeCardBackground: string

    pollHistoryDivider: string
    pollHistoryIcon: string

    pollListCopyBannerBackground: string
    pollListCopyBannerIconBackground: string
    pollListCopyBannerIcon: string

    step2SkeletonCardTitle: string
    step2SkeletonCardSubtitle: string
    step2FilterDivider: string

    primaryIcon: string
    secondaryIcon: string
}

interface FontProps {
    fontFamily: string
    fontSize: number
    lineHeight: number
    color?: string
}

interface AvailableFonts {
    // App // items used throughout the app globally
    phoneButtonText: FontProps
    phoneButtonIcon: FontProps
    backButtonText: FontProps
    backButtonIcon: FontProps
    errorText: FontProps
    textUnderImage: FontProps

    // Top Logo
    logoHeader: FontProps // -< added >-
    logoHeaderMobile: FontProps // -< added >-
    logoSubheader: FontProps // -< added >-

    // Powered by Logo
    poweredByHeader: FontProps
    poweredBySubheader: FontProps

    input: FontProps // -< added >-

    // Bottom Navigation Bar //
    bottomNavigationFlagText: FontProps // notification for the user after they have selected an option or made an update

    // Poll Title //
    createPollTitleWeb: FontProps
    createPollTitleMobile: FontProps
    pollTitleActiveWeb: FontProps
    pollTitleActiveMobile: FontProps

    // Help Text //
    helpTextPrimary: FontProps
    helpTextSecondary: FontProps

    // Example Poll Title //
    examplePollText: FontProps // text that floats above the suggestions or auotcompletes above the keyboard

    // CTAs //
    ctaIcon: FontProps // button icons are technical controlled by font parameters (such as font size)
    ctaPrimaryText: FontProps // e.g. submit button
    ctaSecondaryText: FontProps // e.g. add option button
    ctaFloatingText: FontProps // e.g. cancel when editing name in profile

    navbarDefaultIcon: FontProps
    navbarErrorIcon: FontProps

    // Option Cards //
    optionCardTextPlaceHolder: FontProps
    optionCardInputText: FontProps
    optionCardTextActive: FontProps
    optionCardDateTimeText: FontProps
    optionCardTextVoteCount: FontProps
    optionCardLinkTitle: FontProps
    optionCardLinkSubtitle: FontProps
    optionCardLink: FontProps

    // Default Card template //
    defaultCardTemplateTitle: FontProps
    defaultCardTemplateSubtitle: FontProps
    defaultCardTemplateDescription: FontProps
    defaultCardTemplateLink: FontProps

    // Profile Page //
    profileSectionTitle: FontProps
    profileLinkText: FontProps
    profileLinkIcon: FontProps
    profileLogOutText: FontProps
    versionText: FontProps

    //  Home Page //
    homePageTitle: FontProps
    homePageSubtitle: FontProps
    //homeInfoLine: FontProps not in use!
    //homeInfoLineGrey: FontProps not in use!
    homeGreeting: FontProps
    homeName: FontProps
    pollHistoryHeader: FontProps
    pollHistoryEmptyTitle: FontProps
    pollHistoryItem: FontProps

    // Consent Page //
    consentPageTitle: FontProps
    consentPageSubtitle: FontProps
    consentGreeting: FontProps
    consentInfoLine: FontProps
    consentPageAgreeButtonTitle: FontProps
    // consentInfoLineGrey: FontProps  not in use!

    // Get App Page //
    getAppTitle: FontProps
    getAppSubtitle: FontProps
    getAppQrTitle: FontProps

    // Profile Page User Info //
    profileAvatarIcon: FontProps
    profileAvatarIconSmall: FontProps
    profileAvatarIconTiny: FontProps
    profileAvatarLetter: FontProps
    profileAvatarLetterSmall: FontProps
    profileAvatarLetterTiny: FontProps
    profileUserNameText: FontProps
    profileUserNameIcon: FontProps
    profilePhoneText: FontProps
    profilePhoneIcon: FontProps

    // drawer/modal
    modalCloseIcon: FontProps

    //Logout modal
    logoutModalTitle: FontProps //perhaps notificationPrimaryText
    logoutModalSubtile: FontProps
    logoutModalSubtileDesktop: FontProps
    logoutModalLogoutButtonText: FontProps
    logoutModalSmallLogoutButtonText: FontProps
    logoutModalCancelButtonText: FontProps
    logoutModalSmallCancelButtonText: FontProps

    //Logout modal
    discardModalTitle: FontProps //perhaps notificationPrimaryText
    discardModalSubtile: FontProps
    discardModalSubtileDesktop: FontProps
    discardModalLogoutButtonText: FontProps
    discardModalSmallLogoutButtonText: FontProps
    discardModalCancelButtonText: FontProps
    discardModalSmallCancelButtonText: FontProps

    // Poll Default Settings //
    profileSettingsCardPrimaryText: FontProps
    profileSettingsCardPrimaryIcon: FontProps
    profileSettingsCardSecondaryText: FontProps

    // Drawer/Modals //
    notificationPrimaryText: FontProps
    notificationSecondaryText: FontProps
    notificationSectionTitle: FontProps
    notificationTextMeSectionTitle: FontProps
    notificationPollUrlText: FontProps // e.g. Poll URL to copy in share drawer

    // Customer info Modal
    customerInfoModalHeader: FontProps
    customerInfoModalItemTitle: FontProps
    customerInfoModalItemSubtitle: FontProps

    // See Who Voted //
    voterListSectionHeader: FontProps
    voterListNamesText: FontProps
    voterListNumbersText: FontProps
    voterListTitle: FontProps

    // Poll Send Settings //
    pollSettingsText: FontProps // text below which says app name for sharing via SMS, email, WhatsApp, Facebook, etc.

    // Share Icons //
    shareViaAppButtonText: FontProps

    // Verify Phone Number //
    phoneNumberCardTextPlaceHolder: FontProps
    phoneNumberCardTextActive: FontProps
    phoneNumberCardTextBottom: FontProps
    verificationCodeCardTextPlaceholder: FontProps
    verificationCodeCardInput: FontProps
    verificationCodeCardTextActive: FontProps
    verificationCodeCardTitle: FontProps
    verificationCodeCardSubtitle: FontProps
    verificationCodeCardPhoneNumber: FontProps
    verificationCodeResendCode: FontProps
    phoneNumberTitle: FontProps
    phoneNumberSubtitle: FontProps
    phoneNumberBottomMessage: FontProps

    // Vote Share Links
    voteShareTitle: FontProps
    voteShareStepText: FontProps
    voteShareShareVoteText: FontProps
    voteShareCopyText: FontProps
    voteShareCopyIcon: FontProps
    // Voter Add Name

    voterAddNameTitle: FontProps
    voterAddNameSubtitle: FontProps

    // Preview image
    previewImagePollTitle: FontProps
    previewImageOptionTitle: FontProps
    previewImageOptionSubtitle: FontProps
    previewImageVotesCountText: FontProps

    // Create Poll Send Settings
    createPollSendSettingsSubtitle: FontProps
    createPollSendSettingsOption: FontProps
    createPollSendSettingsCheckIcon: FontProps

    postVoteDownloadTitleText: FontProps
    postVoteDownloadSubtitleText: FontProps

    pollListCopyBannerTitle: FontProps
    pollListCopyBannerSubtitle: FontProps

    toolTipSubmitTitleText: FontProps
    toolTipSubmitSubtitleText: FontProps

    step2FilterAll: FontProps
    step2FilterNone: FontProps
    step2OptionsSelectedCounterValid: FontProps
    step2OptionsSelectedCounterInvalid: FontProps
    step2OptionsCounterText: FontProps

    //Share poll modal
    sharePollTitle: FontProps
    sharePollPhoneTitle: FontProps
    sharePollSubtitle: FontProps
    sharePollScanTitle: FontProps
    sharePollScanDescription: FontProps
}

interface Shadow {
    web: {
        boxShadow: string
    }
    native: {
        shadowColor: string
        shadowOpacity: number
        shadowRadius: number
        elevation: number
        shadowOffset: { width: number; height: number }
    }
}

interface AvailableShadows {
    input: Shadow

    // App //
    pollHeader: Shadow
    appCardDesktopExterior: Shadow
    shareLinkShadow: Shadow

    // see who voted
    seeWhoVoted: Shadow

    // Bottom Navigation //
    bottomNavigationCard: Shadow
    bottomNavigationFlagBackground: Shadow

    // CTAs //
    cta: Shadow

    // Option Card //
    optionCard: Shadow

    // Vote Card //
    voteCard: Shadow

    // Profile User Info //
    profileNameFieldInteriorShadow: Shadow

    // Poll Default Settings //
    profileSettingsCard: Shadow

    // Modals / Drawer //
    modal: Shadow
    pollUrlCard: Shadow

    // Poll Send Settings //
    pollSettingsCard: Shadow

    // Share Drawer //
    shareViaAppButton: Shadow

    // Verify Phone Number //
    phoneNumberCardBackground: Shadow
    verificationCodeCardBackground: Shadow
    phoneNumberInputShadow: Shadow
    imageWithShadowContainer: Shadow
    errorBoxShadow: Shadow
}

interface AvailableBorderRadiuses {
    button: number
    modal: number
    errorModal: number
    bottomNav: number
    CTA: number
    pills: number
    pollSendSetting: number
    drawer: number
    input: number
}

interface AvailableCornerRadiuses {
    // Bottom Navigation //
    bottomNavigationCard: number
    bottomNavigationFlagBackground: number

    // Example Poll Card //
    examplePollCard: number

    // CTAs //
    cta: number

    // input text //
    input: number

    // Option Card //
    optionCard: number

    // Poll Default Settings Card //
    profileSettingsCard: number

    // Modal //
    modal: number

    // Drawer //
    drawer: number

    // Poll Send Settings //
    pollSettingsCard: number

    // Verify Phone Number //
    phoneNumberCardBackground: number
    verificationCodeCardBackground: number
}

interface AvailableImages {
    // App //
    appLogo: ImageSourcePropType
    previewImageBackground: ImageSourcePropType | null
    previewImageLogo?: base64Image
    previewImageCard: ImageSourcePropType
    appBackgroundMobile: string
    appBackgroundDesktop: string
    splashImage: ImageSourcePropType
    homeScreenImage: ImageSourcePropType
    consentScreenImage: ImageSourcePropType
    backIcon: string
    checkboxChecked: string
    checkboxUnchecked: string
    pollHistoryHeader: ImageSourcePropType
    pollHistoryEmpty: ImageSourcePropType
    imagePlaceholder: base64Image

    closeX: string
    linkOut: string

    // Bottom Navigation //
    navBarTrash: string
    navBarProfile: string
    navBarEdit: string
    navBarHome: string
    navBarShare: string
    navBarInfo: string

    // Example Poll Card //
    examplePollSuggestionButton: string
    examplePollAddButton: string
    examplePollDateAndTime: string

    // Option Card //
    optionCardVoteCounterActive: string
    optionCardVoteCounterInactive: string
    optionCardLocked: string
    optionCardRemove: string
    optionCardLink: string
    optionCardAddedConfirmation: string
    optionCardCancel: string
    optionCardDrag: string

    // Tap to Paste Button //

    // Profile //
    userNameEdit: string
    profileAvatar: string
    profileAuthenticateButtonIcon: string
    profilePhoneNumberIcon: string
    profileLinkOutIcon: string
    profileShareFeedbackIcon: string
    userSettingsEditPoll: string
    userSettingsMultiVote: string
    userSettingsSeeWhoVoted: string

    // Modal/Drawer //
    pollSendSettingsGraphic: base64Image
    pollLockedGraphic: base64Image
    addUserNameGraphic: base64Image
    loadPollFailedGraphic: base64Image
    launchPollFailedGraphic: base64Image
    updatePollFailedGraphic: base64Image
    updatePollCrossErrorGraphic: base64Image
    missingOptionsGraphic: base64Image
    pollAnonymousGraphic: string
    shareVoteGraphic: base64Image
    userAuthenticateGraphic: base64Image
    pollsAdvertise: base64Image
    rememberSubmit: base64Image
    rememberSubmitBackground: base64Image
    postVotePromo: ImageSourcePropType | null

    // Share Icons //
    shareViaSMS: ImageSourcePropType
    shareViaEmail: ImageSourcePropType
    shareViaMessenger: ImageSourcePropType
    shareViaWhatsApp: ImageSourcePropType
    shareViaIMessages: ImageSourcePropType
    shareCopyButtonCheckmark: string

    // Get App Screen
    getAppScreenImage: base64Image
}
interface AvailableLotties {
    checkmark: string
    checkmarkDone: string
    checkBox: string
    whiteSpinner: string
    spinner: string
    trashCan: string
    createPollSuccess: string
    signInSuccess: string
    voteSuccess: string
    success: string
}

export type FontFiles = Record<string, FontSource>

export interface Theme {
    color: AvailableColors
    font: AvailableFonts
    shadow: AvailableShadows
    cornerRadius: AvailableCornerRadiuses
    image: AvailableImages
    layout: any
    lottie: AvailableLotties

    //old stuff to be removed
    borderRadius: AvailableBorderRadiuses
    fontFiles: FontFiles
}

export interface ThemeOverrides {
    color?: Partial<AvailableColors>
    font?: Partial<AvailableFonts>
    shadow?: Partial<AvailableShadows>
    cornerRadius?: Partial<AvailableCornerRadiuses>
    image?: Partial<AvailableImages>
    layout?: Partial<any>
    lottie?: Partial<AvailableLotties>

    //old stuff to be removed
    borderRadius?: AvailableBorderRadiuses
    fontFiles?: FontFiles
}

export interface FontPalette {
    regular: string
    semiBold: string
    bold: string

    pollsRegular: "sf-pro-display"
    pollsSemiBold: "sf-pro-display-semibold"
    pollsBold: "sf-pro-display-bold"
}

export interface ColorPalette {
    // main colors
    primary: string // main color of the theme used in general
    secondary: string // accent color

    // backgrounds
    cardBackground: string
    screenBackground: string
    overlay: string // when we darken/lightne the background when displaying modals

    // icons
    primaryIcon: string // e.g. tab bar icons
    secondaryIcon: string // e.g. re-order, close

    // buttons
    primaryCtaBackground: string
    primaryCtaBackgroundInactive: string
    primaryCtaTextActive: string
    primaryCtaTextDisabled: string
    secondaryCtaBackground: string
    secondaryCtaTextActive: string
    secondaryCtaTextDisabled: string
    tertiaryCtaBackground: string
    tertiaryCtaTextActive: string
    tertiaryCtaTextDisabled: string
    floatingCTAText: string
    floatingCTATextDisabled: string

    // text
    t1: string
    t2: string
    t3: string
    regularText: string // view details button
    placeholder: string
    error: string
    linkText: string
    t1Inverted: string

    // misc
    shadow: string
    flag: string // submit to save changes
    divider: string
    banner: string // like the paste links tooltip
    destructive: string // e.g. delete poll
    success: string // button turns green after copying link

    // Polls Colors (our palette)
    pollPrimaryTextColor: string
}

export const defaultColorPalette: ColorPalette = {
    // main colors
    primary: "#007afe", // main color of the theme used in general
    secondary: "#ebebeb", // accent color

    // backgrounds
    screenBackground: "#fff", // color of screen on mobile, or poll card on desktop, does not determine backdrop outside the poll card
    cardBackground: "#fff", // all cards, option cards, settings cards, modals, drawers
    overlay: "#fff", // color used behind a mondal to "blur" the background

    // icons
    primaryIcon: "#007afe", // e.g. tab bar icons
    secondaryIcon: "#ebebeb", // e.g. re-order, close

    // buttons
    primaryCtaBackground: "#007afe",
    primaryCtaBackgroundInactive: "#9b9b9b",
    primaryCtaTextActive: "#FFFFFF",
    primaryCtaTextDisabled: "#FFFFFF",

    secondaryCtaBackground: "#fff",
    secondaryCtaTextActive: "#007afe",
    secondaryCtaTextDisabled: "#007afe",

    tertiaryCtaBackground: "#", // tertiary cta (is this floating, or do we have tertiary AND floating)
    tertiaryCtaTextActive: "#",
    tertiaryCtaTextDisabled: "#",

    floatingCTAText: "#007AFE",
    floatingCTATextDisabled: "9b9b9b",

    // text
    t1: "#343E57", //all primary text, such as poll title, option card title, etc.
    t2: "#535C71", //all secondary text, such as help text, option subtitle, option details
    t3: "#9b9b9b", //help text and option details
    regularText: "#343E57",
    t1Inverted: "#fff",
    placeholder: "#ebebeb", //placeholder text before user types poll or option titles
    linkText: "#007AFE",
    error: "#FE281C",

    // misc
    shadow: "#1C1C1C", // color needed for dark shadows
    flag: "#FF9500", //color to draw attention such as submit to save changes banner
    divider: "#E0E0E0", //divider lines
    banner: "#EFF5FF", //banners such as the paste links banner
    destructive: "#FE281C", //for deleting things, typically red
    success: "#05CB4F",

    // Polls Colors (our palette)
    pollPrimaryTextColor: "#343E57",

    // dark mode

    // // main colors
    // primary: "#17CE85", // main color of the theme used in general
    // secondary: "#4DA1FF", // accent color

    // // backgrounds
    // screenBackground:"#0E0E0F",  // color of screen on mobile, or poll card on desktop, does not determine backdrop outside the poll card
    // cardBackground: "#1C1C20",  // all cards, option cards, settings cards, modals, drawers
    // overlay: "#000000", // color used behind a mondal to "blur" the background

    // // icons
    // primaryIcon: "#17CE85", // e.g. tab bar icons
    // secondaryIcon: "#ebebeb", // e.g. re-order, close

    // // buttons
    // primaryCtaBackground:"#17CE85",
    // primaryCtaBackgroundInactive: "#9b9b9b",
    // primaryCtaTextActive:"#FFFFFF",
    // primaryCtaTextDisabled: "#FFFFFF",

    // secondaryCtaBackground: "#1C1C20",
    // secondaryCtaTextActive: "#FFFFFF",
    // secondaryCtaTextDisabled: "#FFFFFF",

    // tertiaryCtaBackground: "#",  // tertiary cta (is this floating, or do we have tertiary AND floating)
    // tertiaryCtaTextActive: "#",
    // tertiaryCtaTextDisabled: "#",

    // // text
    // primaryText: "#FFFFFF",  //all primary text, such as poll title, option card title, etc.
    // secondaryText: "#818182",  //all secondary text, such as help text, option subtitle, option details
    // tertiaryText: "#17CE85", //
    // placeholder: "#5C5C5C", //placeholder text before user types poll or option titles
    // linkText: "#007AFE",
    // error: "#FE281C",

    // // misc
    // shadow: "#1C1C1C", // color needed for dark shadows
    // flag: "#17CE85", //color to draw attention such as submit to save changes banner
    // divider: "#E0E0E0", //divider lines
    // banner: "#", //banners such as the paste links banner
    // destructive: "#FE281C", //for deleting things, typically red
    // success: "#05CB4F",

    // // Polls Colors (our palette)
    // pollPrimaryTextColor: "#343E57",
}

export const defaultDarkColorPalette: ColorPalette = {
    // main colors
    primary: "#007afe", // main color of the theme used in general
    secondary: "#ebebeb", // accent color

    // backgrounds
    screenBackground: "#0E0E0F", // color of screen on mobile, or poll card on desktop, does not determine backdrop outside the poll card
    cardBackground: "#1C1C20", // all cards, option cards, settings cards, modals, drawers
    overlay: "#000000", // color used behind a mondal to "blur" the background

    // icons
    primaryIcon: "#007afe", // e.g. tab bar icons
    secondaryIcon: "#ebebeb", // e.g. re-order, close

    // buttons
    primaryCtaBackground: "#007afe",
    primaryCtaBackgroundInactive: "#5C5C5C",
    primaryCtaTextActive: "#FFFFFF",
    primaryCtaTextDisabled: "#FFFFFF",

    secondaryCtaBackground: "#1C1C20",
    secondaryCtaTextActive: "#007afe",
    secondaryCtaTextDisabled: "#FFFFFF",

    tertiaryCtaBackground: "#", // tertiary cta (is this floating, or do we have tertiary AND floating)
    tertiaryCtaTextActive: "#",
    tertiaryCtaTextDisabled: "#",

    floatingCTAText: "#007AFE",
    floatingCTATextDisabled: "9b9b9b",

    // text
    t1: "#FFFFFF", //all primary text, such as poll title, option card title, etc.
    t2: "#818182", //all secondary text, such as help text, option subtitle, option details
    t3: "#5C5C5C", //help text and option details
    regularText: "#343E57",
    t1Inverted: "#FFFFFF",
    placeholder: "#5C5C5C", //placeholder text before user types poll or option titles
    linkText: "#007AFE",
    error: "#FE281C",

    // misc
    shadow: "#1C1C1C", // color needed for dark shadows
    flag: "#FF9500", //color to draw attention such as submit to save changes banner
    divider: "#E0E0E0", //divider lines
    banner: "#1C1C20", //banners such as the paste links banner
    destructive: "#FE281C", //for deleting things, typically red
    success: "#05CB4F",

    // Polls Colors (our palette)
    pollPrimaryTextColor: "#343E57",
}

export const createTheme = (options: {
    fontPalette: FontPalette
    fontFiles: FontFiles
    colorPalette: Partial<ColorPalette>
    overrides: ThemeOverrides
    fontSizeMultiplier: number
    lineHeightMultiplier: number
}): Theme => {
    const {
        fontPalette,
        fontFiles,
        colorPalette: partialColorPalette,
        overrides,
        fontSizeMultiplier,
        lineHeightMultiplier,
    } = options

    const colorPalette: ColorPalette = {
        ...defaultColorPalette,
        ...partialColorPalette,
    }

    const theme: Theme = {
        fontFiles: {
            "sf-pro-display": require("@assets/fonts/SFProDisplay-Regular.ttf"),
            "sf-pro-display-semibold": require("@assets/fonts/SFProDisplay-Semibold.ttf"),
            "sf-pro-display-bold": require("@assets/fonts/SFProDisplay-Bold.ttf"),
            arial: require("@assets/fonts/Arial.ttf"),
            ...fontFiles,
        },
        color: {
            // App // items used throughout the app globally
            skeletonDark: colorPalette.t2, //Skeleton primary
            skeletonLight: colorPalette.t3, //Skeleton secondary
            skeletonHighlight: colorPalette.t1Inverted,
            appBackgroundMobile: colorPalette.screenBackground, // colorPalette.white,
            appBackgroundDesktop: colorPalette.screenBackground,
            appBackgroundCardDesktop: colorPalette.screenBackground,
            modalBackground: colorPalette.screenBackground,
            modalOverlay: colorPalette.overlay,
            modalDivider: colorPalette.divider,
            divider: colorPalette.divider,
            navHeaderBackground: "transparent",

            inputBoxColor: colorPalette.cardBackground,
            inputBoxBorderColor: colorPalette.primary, // not using??
            inputPlaceholderColor: colorPalette.placeholder,

            // Bottom Navigation // notification for the user after they have selected an option or made an update
            bottomNavigationBackgroundColor: colorPalette.cardBackground,
            bottomNavigationFlagBackground: colorPalette.flag,

            // Poll Title //
            titleContainer: colorPalette.cardBackground, // not used?
            pollTitleCursor: colorPalette.primary,
            pollTitleDivider: colorPalette.divider,

            // Help Text // this is floating text on the set poll title screen and add options screens
            helpTextPrimary: colorPalette.t3, // not used
            helpTextSecondary: colorPalette.t3, // not used

            // CTAs // the primary tappable buttons and different states of each
            ctaPrimaryInactiveBackground:
                colorPalette.primaryCtaBackgroundInactive, // e.g. submit button when disabled
            ctaPrimaryActiveBackground: colorPalette.primaryCtaBackground, // e.g. submit button when enabled
            ctaPrimarySuccessBackground: colorPalette.success, // e.g. after copying link on share poll screen
            ctaPrimaryText: colorPalette.primaryCtaTextActive, // e.g. submit button title
            ctaPrimaryDisabledText: colorPalette.primaryCtaTextDisabled, // same button in disabled state
            ctaSecondaryInactiveBackground:
                colorPalette.primaryCtaBackgroundInactive, // e.g. add option button
            ctaSecondaryActiveBackground: colorPalette.secondaryCtaBackground,
            ctaSecondaryText: colorPalette.secondaryCtaTextActive, // e.g. add option button title
            ctaSecondaryDisabledText: colorPalette.secondaryCtaTextDisabled, // e.g. add option button title
            ctaSecondaryTextFailure: colorPalette.error, // e.g. error messages
            ctaFloatingText: colorPalette.floatingCTAText, // e.g. Cancel button when editing user name in profile, and icons on bottom navication
            ctaFloatingDisabledText: colorPalette.floatingCTATextDisabled, // e.g. Cancel button when editing user name in profile
            ctaDelete: colorPalette.destructive,

            // Option Cards // Option cards both in draft and active states
            optionCardBackground: colorPalette.cardBackground,
            optionCardTextCursor: colorPalette.primary,
            optionCardDateTimeText: colorPalette.t2, // second line of text for date time autocomplete cards
            optionCardProgressBarBackground: colorPalette.secondary,
            optionCardProgressBarFill: colorPalette.primary,
            optionCardLinkText: colorPalette.linkText, // domain text color e.g. airbnb.com
            optionCardLockedIcon: colorPalette.secondaryIcon,
            optionCardRemoveIcon: colorPalette.destructive, // red delete button
            optionCardDeleteIcon: colorPalette.secondaryIcon, // the gray x button
            optionCardGroupIcon: colorPalette.secondaryIcon, // re-order icon
            optionCardCheckbox: colorPalette.primary,
            optionCardSkeletonBackground: colorPalette.cardBackground,

            // Profile Page //
            profileAvatarBackground: colorPalette.primary,
            checkBox: colorPalette.primary,

            // Profile Page User Info // User specific info on the profile page
            profileUpdateNameTextCursor: colorPalette.primary,

            // Poll Default Settings //
            profileSettingsCardBackground: colorPalette.cardBackground,

            // Drawers/Modal //
            notificationPollUrlCardBackground: colorPalette.cardBackground, // e.g. Card for Poll URL to copy in share drawer

            // Customer info modal
            customerInfoModalDivider: colorPalette.divider,
            customerInfoModalItemIcon: colorPalette.primary,
            customerInfoModalItemIconContainer: colorPalette.banner,

            // Poll Send Settings // Individual controls before the poll creator sends the poll
            pollSettingCard: colorPalette.cardBackground,
            pollSettingText: colorPalette.t1,

            pollOverlay: colorPalette.overlay,

            // Share Buttons //
            shareViaAppButtonBackgroundColor: colorPalette.cardBackground, // circle with app icon it in for sharing via SMS, email, WhatsApp, Facebook, etc.
            shareViaAppButtonText: colorPalette.regularText, // text below which says app name for sharing via SMS, email, WhatsApp, Facebook, etc.
            shareQrCodeBackground: colorPalette.cardBackground,
            shareQrCodeForeground: colorPalette.t1,

            // Verify Phone Number // Flow for users to enter phone numbers and verification codes to sign in
            phoneNumberCardBackground: colorPalette.cardBackground,
            phoneNumberCardTextPlaceHolder: colorPalette.placeholder,
            phoneNumberCardTextActive: colorPalette.t1,
            verificationCodeCardBackground: colorPalette.cardBackground,

            pollHistoryDivider: colorPalette.divider,
            pollHistoryIcon: colorPalette.t1, // not used?

            pollListCopyBannerBackground: colorPalette.banner,
            pollListCopyBannerIconBackground: colorPalette.cardBackground,
            pollListCopyBannerIcon: colorPalette.primary,

            step2SkeletonCardTitle: colorPalette.t2,
            step2SkeletonCardSubtitle: colorPalette.t3,
            step2FilterDivider: colorPalette.divider,

            primaryIcon: colorPalette.primaryIcon,
            secondaryIcon: colorPalette.secondaryIcon,
        },

        font: {
            // App // items used throughout the app globally
            phoneButtonText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.t2,
            },
            phoneButtonIcon: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.primary,
            },
            backButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.primary,
            },
            backButtonIcon: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.primary,
            },
            errorText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.error,
            },
            textUnderImage: {
                //text under share via (SMS, Messenger, Whatsapp)
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 13,
                lineHeight: lineHeightMultiplier * 13,
                color: colorPalette.t2,
            },
            logoHeader: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 23,
                lineHeight: lineHeightMultiplier * 23,
                color: colorPalette.t1Inverted,
            },
            logoHeaderMobile: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.t1,
            },
            logoSubheader: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
                color: colorPalette.t1Inverted,
            },

            poweredByHeader: {
                fontFamily: fontPalette.pollsBold,
                fontSize: fontSizeMultiplier * 17,
                lineHeight: lineHeightMultiplier * 17,
                color: colorPalette.t1Inverted,
            },
            poweredBySubheader: {
                fontFamily: fontPalette.pollsSemiBold,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
                color: colorPalette.t1Inverted,
            },

            input: {
                //Not sure what this is
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.regularText,
            },

            // Bottom Navigation Bar //
            bottomNavigationFlagText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 13,
                lineHeight: lineHeightMultiplier * 13,
                color: colorPalette.t1Inverted,
            }, // notification for the user after they have selected an option or made an update

            // Poll Title //
            createPollTitleWeb: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 26,
                color: colorPalette.t1,
            },
            createPollTitleMobile: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 20,
                color: colorPalette.t1,
            },
            pollTitleActiveWeb: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 26,
                color: colorPalette.t1,
            },
            pollTitleActiveMobile: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 20,
                color: colorPalette.t1,
            },

            // Help Text //
            helpTextPrimary: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 21,
                lineHeight: lineHeightMultiplier * 21,
                color: colorPalette.t3,
            },
            helpTextSecondary: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 20,
                color: colorPalette.t3,
            },

            // Example Poll Title //
            examplePollText: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
            }, // text that floats above the suggestions or auotcompletes above the keyboard

            // CTAs //
            ctaIcon: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 20,
            },
            ctaPrimaryText: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 19,
            }, // e.g. submit button
            ctaSecondaryText: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
            }, // e.g. add option button
            ctaFloatingText: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
                color: colorPalette.floatingCTAText,
            }, // e.g. cancel when editing name in profile
            navbarDefaultIcon: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 20,
                color: colorPalette.t1,
            },
            navbarErrorIcon: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 20,
                color: colorPalette.destructive,
            },

            // Option Cards //
            optionCardTextPlaceHolder: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 14.5,
                lineHeight: lineHeightMultiplier * 14.5,
                color: colorPalette.placeholder,
            },
            optionCardInputText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 15,
                lineHeight: lineHeightMultiplier * 17,
                color: colorPalette.t1,
            },
            optionCardTextActive: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 14.5,
                lineHeight: lineHeightMultiplier * 14.5,
                color: colorPalette.t1,
            },
            optionCardDateTimeText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
                color: colorPalette.t2,
            },

            optionCardTextVoteCount: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 12,
                lineHeight: lineHeightMultiplier * 12,
                color: colorPalette.t1,
            },

            optionCardLinkTitle: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: fontSizeMultiplier * 16,
                color: colorPalette.t1,
            },

            optionCardLinkSubtitle: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 11,
                lineHeight: fontSizeMultiplier * 11,
                color: colorPalette.t2,
            },

            optionCardLink: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 12,
                lineHeight: fontSizeMultiplier * 12,
                color: colorPalette.linkText,
            },

            // default card template
            defaultCardTemplateTitle: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.t1,
            },
            defaultCardTemplateSubtitle: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 10,
                lineHeight: lineHeightMultiplier * 14,
                color: colorPalette.t2,
            },
            defaultCardTemplateDescription: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 10,
                lineHeight: lineHeightMultiplier * 14,
                color: colorPalette.t3,
            },
            defaultCardTemplateLink: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 11,
                lineHeight: lineHeightMultiplier * 11,
                color: colorPalette.linkText,
            },

            // Home Page //
            homePageTitle: {
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 35,
                lineHeight: lineHeightMultiplier * 35,
                fontFamily: fontPalette.bold,
            },
            homePageSubtitle: {
                color: colorPalette.t3,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                fontFamily: fontPalette.bold,
            },
            homeName: {
                color: colorPalette.t1,
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 35,
                lineHeight: lineHeightMultiplier * 35,
            },
            homeGreeting: {
                color: colorPalette.t2,
                fontSize: fontSizeMultiplier * 23,
                fontFamily: fontPalette.bold,
                lineHeight: lineHeightMultiplier * 23,
            },
            pollHistoryHeader: {
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 21,
                lineHeight: lineHeightMultiplier * 21,
                fontFamily: fontPalette.bold,
            },
            pollHistoryEmptyTitle: {
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                fontFamily: fontPalette.regular,
            },
            pollHistoryItem: {
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
                fontFamily: fontPalette.semiBold,
            },

            // Consent Page //
            consentPageTitle: {
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 36,
                lineHeight: lineHeightMultiplier * 36,
                fontFamily: fontPalette.bold,
            },
            consentPageSubtitle: {
                color: colorPalette.t2,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                fontFamily: fontPalette.semiBold,
            },
            consentGreeting: {
                fontFamily: fontPalette.bold,
                lineHeight: lineHeightMultiplier * 23,
                fontSize: fontSizeMultiplier * 23,
                color: colorPalette.t1,
            },
            consentInfoLine: {
                color: colorPalette.t2,
                fontSize: fontSizeMultiplier * 12,
                lineHeight: lineHeightMultiplier * 13,
                fontFamily: fontPalette.regular,
            },
            consentPageAgreeButtonTitle: {
                fontSize: 30,
                lineHeight: 32,
                fontFamily: fontPalette.bold,
                color: colorPalette.primary, // eh, this is kind of a unique button
            },

            // Get App Page //
            getAppTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 36,
                lineHeight: lineHeightMultiplier * 36,
                color: colorPalette.t1,
            },
            getAppSubtitle: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.t2,
            },
            getAppQrTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.t2,
            },

            // Profile Page //
            profileSectionTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 22,
                color: colorPalette.t2,
            },
            profileLinkText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.t1,
            },
            versionText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
                color: colorPalette.t1,
            },
            profileLinkIcon: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 12,
                lineHeight: lineHeightMultiplier * 12,
                color: colorPalette.primary,
            },
            profileLogOutText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.floatingCTAText,
            },

            // drawer modal
            modalCloseIcon: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.secondaryIcon,
            },

            // logout modal
            //perhaps notificationPrimaryText
            logoutModalTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.t1,
            },
            logoutModalSubtile: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 13,
                lineHeight: lineHeightMultiplier * 13,
                color: colorPalette.t2,
            },
            logoutModalSubtileDesktop: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.t2,
            },
            logoutModalLogoutButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 21,
                lineHeight: lineHeightMultiplier * 21,
                color: colorPalette.destructive,
            },
            logoutModalSmallLogoutButtonText: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.destructive,
            },
            logoutModalCancelButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 21,
                lineHeight: lineHeightMultiplier * 21,
                color: colorPalette.floatingCTAText,
            },
            logoutModalSmallCancelButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.floatingCTAText,
            },

            // discard modal
            //perhaps notificationPrimaryText
            discardModalTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.t1,
            },
            discardModalSubtile: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 13,
                lineHeight: lineHeightMultiplier * 13,
                color: colorPalette.t2,
            },
            discardModalSubtileDesktop: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.t2,
            },
            discardModalLogoutButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 21,
                lineHeight: lineHeightMultiplier * 21,
                color: colorPalette.destructive,
            },
            discardModalSmallLogoutButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.destructive,
            },
            discardModalCancelButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 21,
                lineHeight: lineHeightMultiplier * 21,
                color: colorPalette.floatingCTAText,
            },
            discardModalSmallCancelButtonText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.floatingCTAText,
            },

            // Profile Page User Info //
            profileAvatarIcon: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 60,
                lineHeight: lineHeightMultiplier * 68,
                color: colorPalette.t1Inverted,
            },
            profileAvatarIconSmall: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 40,
                color: colorPalette.t1Inverted,
            },
            profileAvatarIconTiny: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 10,
                lineHeight: lineHeightMultiplier * 10,
                color: colorPalette.t1,
            },
            profileAvatarLetter: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 60,
                lineHeight: lineHeightMultiplier * 68,
                color: colorPalette.t1Inverted,
            },
            profileAvatarLetterSmall: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 26,
                color: colorPalette.t1Inverted,
            },
            profileAvatarLetterTiny: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 10,
                lineHeight: lineHeightMultiplier * 10,
                color: colorPalette.t1Inverted,
            },
            profileUserNameText: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 21,
                lineHeight: lineHeightMultiplier * 21,
                color: colorPalette.t1,
            },
            profileUserNameIcon: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 17,
                color: colorPalette.primary,
            },
            profilePhoneText: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 15,
                lineHeight: lineHeightMultiplier * 15,
                color: colorPalette.t2,
            },
            profilePhoneIcon: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
                color: colorPalette.primary,
            },

            // Poll Default Settings //
            profileSettingsCardPrimaryText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.t1,
            },
            profileSettingsCardPrimaryIcon: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.t1, // TODO: this is a standalone 3rd icon style
            },
            profileSettingsCardSecondaryText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 12,
                lineHeight: lineHeightMultiplier * 12,
                color: colorPalette.t2,
            },

            // Drawer/Modals //
            notificationPrimaryText: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.t1,
            },
            notificationSecondaryText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.t1,
            },
            notificationSectionTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 20,
                lineHeight: lineHeightMultiplier * 22,
                color: colorPalette.t1,
            },
            notificationTextMeSectionTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.t1,
            },
            notificationPollUrlText: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 15,
                lineHeight: lineHeightMultiplier * 17,
                color: colorPalette.t1,
            }, // e.g. Poll URL to copy in share drawer

            // Customer info modal
            customerInfoModalHeader: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 36,
                lineHeight: lineHeightMultiplier * 36,
                color: colorPalette.t1,
            },
            customerInfoModalItemTitle: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.t1,
            },
            customerInfoModalItemSubtitle: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 12,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.t2,
            },

            // See Who Voted //
            voterListSectionHeader: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 21,
                lineHeight: lineHeightMultiplier * 21,
                color: colorPalette.t1,
            },
            voterListNamesText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
                color: colorPalette.regularText,
            },
            voterListNumbersText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 13,
                lineHeight: lineHeightMultiplier * 13,
                color: colorPalette.t1,
            },
            voterListTitle: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.t1,
            },

            // Poll Send Settings //
            pollSettingsText: {
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
                color: colorPalette.t1,
            },

            // Share Icons //
            shareViaAppButtonText: {
                // text below which says app name for sharing via SMS, email, WhatsApp, Facebook, etc.
                fontFamily: fontPalette.semiBold,
                fontSize: fontSizeMultiplier * 12,
                lineHeight: lineHeightMultiplier * 12,
                color: colorPalette.t2,
            },

            // Verify Phone Number //
            phoneNumberCardTextPlaceHolder: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.placeholder,
            },
            phoneNumberCardTextActive: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.t1,
            },
            phoneNumberCardTextBottom: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 10,
                lineHeight: lineHeightMultiplier * 10,
                color: colorPalette.regularText,
            },
            verificationCodeCardTextPlaceholder: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 30,
                lineHeight: lineHeightMultiplier * 30,
                color: colorPalette.placeholder,
            },
            verificationCodeCardInput: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 30,
                lineHeight: lineHeightMultiplier * 30,
                color: colorPalette.t1,
            },
            verificationCodeCardTextActive: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.t1,
            },
            verificationCodeCardTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.t1,
            },
            verificationCodeCardSubtitle: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.t2,
            },
            verificationCodeCardPhoneNumber: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.t2,
            },
            verificationCodeResendCode: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.floatingCTAText,
            },
            phoneNumberTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.t1,
            },
            phoneNumberSubtitle: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.t2,
            },
            phoneNumberBottomMessage: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 10,
                lineHeight: lineHeightMultiplier * 10,
                color: colorPalette.t2,
            },

            //VOTE SECTION //
            voteShareTitle: {
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 24,
                fontFamily: fontPalette.bold,
                lineHeight: lineHeightMultiplier * 24,
            },
            voteShareStepText: {
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 24,
                fontFamily: fontPalette.bold,
                lineHeight: lineHeightMultiplier * 24,
            },
            voteShareShareVoteText: {
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 24,
                fontFamily: fontPalette.bold,
                lineHeight: lineHeightMultiplier * 24,
            },

            voteShareCopyText: {
                color: colorPalette.regularText,
                fontSize: fontSizeMultiplier * 16,
                fontFamily: fontPalette.regular,
                lineHeight: lineHeightMultiplier * 16,
            },
            voteShareCopyIcon: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.primaryCtaTextActive,
            },
            voterAddNameTitle: {
                fontFamily: fontPalette.bold,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
                color: colorPalette.t1,
            },
            voterAddNameSubtitle: {
                fontFamily: fontPalette.regular,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
                color: colorPalette.t2,
            },

            // Preview image
            previewImagePollTitle: {
                fontFamily: fontPalette.bold,
                color: colorPalette.t1,
                lineHeight: lineHeightMultiplier * 46,
                fontSize: fontSizeMultiplier * 40,
            },
            previewImageOptionTitle: {
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 30,
                lineHeight: lineHeightMultiplier * 40,
            },
            previewImageOptionSubtitle: {
                fontFamily: fontPalette.regular,
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 28,
                lineHeight: lineHeightMultiplier * 34,
            },
            previewImageVotesCountText: {
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 24,
            },
            // Create Poll Send Settings
            createPollSendSettingsSubtitle: {
                fontFamily: fontPalette.bold,
                color: colorPalette.t2,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
            },
            createPollSendSettingsOption: {
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
            },
            createPollSendSettingsCheckIcon: {
                fontFamily: "",
                color: colorPalette.success,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
            },
            postVoteDownloadTitleText: {
                fontFamily: fontPalette.bold,
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: fontSizeMultiplier * 24,
            },
            postVoteDownloadSubtitleText: {
                fontFamily: fontPalette.regular,
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: fontSizeMultiplier * 14,
            },

            pollListCopyBannerTitle: {
                fontFamily: fontPalette.bold,
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: fontSizeMultiplier * 16,
            },
            pollListCopyBannerSubtitle: {
                fontFamily: fontPalette.regular,
                color: colorPalette.t2,
                fontSize: fontSizeMultiplier * 12,
                lineHeight: fontSizeMultiplier * 12,
            },

            toolTipSubmitTitleText: {
                fontFamily: fontPalette.bold,
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 30,
                lineHeight: lineHeightMultiplier * 36,
            },
            toolTipSubmitSubtitleText: {
                fontFamily: fontPalette.regular,
                color: colorPalette.t2,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 22,
            },

            step2FilterAll: {
                fontFamily: fontPalette.semiBold,
                color: colorPalette.floatingCTAText,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
            },
            step2FilterNone: {
                fontFamily: fontPalette.semiBold,
                color: colorPalette.floatingCTAText,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
            },
            step2OptionsSelectedCounterValid: {
                fontFamily: fontPalette.semiBold,
                color: colorPalette.primary,
                fontSize: fontSizeMultiplier * 36,
                lineHeight: lineHeightMultiplier * 36,
            },
            step2OptionsSelectedCounterInvalid: {
                fontFamily: fontPalette.semiBold,
                color: colorPalette.destructive,
                fontSize: fontSizeMultiplier * 36,
                lineHeight: lineHeightMultiplier * 36,
            },
            step2OptionsCounterText: {
                fontFamily: fontPalette.semiBold,
                color: colorPalette.t2,
                fontSize: fontSizeMultiplier * 14,
                lineHeight: lineHeightMultiplier * 14,
            },

            // Share Poll Modal
            sharePollTitle: {
                fontFamily: fontPalette.bold,
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 24,
                lineHeight: lineHeightMultiplier * 26,
            },
            sharePollPhoneTitle: {
                fontFamily: fontPalette.bold,
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
            },
            sharePollSubtitle: {
                fontFamily: fontPalette.regular,
                color: colorPalette.t2,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
            },
            sharePollScanTitle: {
                fontFamily: fontPalette.bold,
                color: colorPalette.t1,
                fontSize: fontSizeMultiplier * 18,
                lineHeight: lineHeightMultiplier * 18,
            },
            sharePollScanDescription: {
                fontFamily: fontPalette.regular,
                color: colorPalette.t2,
                fontSize: fontSizeMultiplier * 16,
                lineHeight: lineHeightMultiplier * 16,
            },
        },
        shadow: {
            // App //
            input: {
                web: {
                    boxShadow: `inset 0px 0px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 1,
                    shadowRadius: 4,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 0 },
                },
            },
            pollHeader: {
                web: {
                    boxShadow: `0px 0px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },
            appCardDesktopExterior: {
                web: {
                    boxShadow: `0px 0px 20px ${hexToRgbA(
                        colorPalette.shadow,
                        0.2,
                    )}, inset 0px 0px 10px ${hexToRgbA(
                        colorPalette.shadow,
                        0.2,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 0 },
                },
            },
            shareLinkShadow: {
                web: {
                    boxShadow: `0px 2px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 4,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // See who voted //
            seeWhoVoted: {
                web: {
                    boxShadow: `0px 2px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // Bottom Navigation //
            bottomNavigationCard: {
                web: {
                    boxShadow: `0px 2px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },
            bottomNavigationFlagBackground: {
                web: {
                    boxShadow: `0px 2px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // CTAs //
            cta: {
                web: {
                    boxShadow: `0px 2px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // Option Card //
            optionCard: {
                web: {
                    boxShadow: `0px 2px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // Option Card //
            voteCard: {
                web: {
                    boxShadow: `0px 0px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 0 },
                },
            },

            // Profile User Info //
            profileNameFieldInteriorShadow: {
                web: {
                    boxShadow: `0px 2px 10px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // Poll Default Settings //
            profileSettingsCard: {
                web: {
                    boxShadow: `0px 0px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 3,
                    shadowOffset: { width: 0, height: 0 },
                },
            },

            // Modals / Drawer //
            modal: {
                web: {
                    boxShadow: `0px 0px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 0 },
                },
            },

            pollUrlCard: {
                web: { boxShadow: "0px 2px 7px rgba(0,0,0,0.25)" },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // Poll Send Settings //
            pollSettingsCard: {
                web: {
                    boxShadow: `0px 2px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // Share Drawer //
            shareViaAppButton: {
                web: {
                    boxShadow: `0px 2px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },

            // Verify Phone Number //
            phoneNumberCardBackground: {
                web: {
                    boxShadow: `0px 2px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },
            verificationCodeCardBackground: {
                web: {
                    boxShadow: `0px 2px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 7,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },
            phoneNumberInputShadow: {
                web: {
                    boxShadow: `0px 2px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },
            imageWithShadowContainer: {
                web: { boxShadow: "" },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOffset: {
                        width: 0,
                        height: 5,
                    },
                    shadowOpacity: 0.34,
                    shadowRadius: 6.27,
                    elevation: 5,
                },
            },
            errorBoxShadow: {
                web: {
                    boxShadow: `0px 2px 7px ${hexToRgbA(
                        colorPalette.shadow,
                        0.25,
                    )}`,
                },
                native: {
                    shadowColor: colorPalette.shadow,
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    elevation: 5,
                    shadowOffset: { width: 0, height: 2 },
                },
            },
        },
        cornerRadius: {
            // Bottom Navigation //
            bottomNavigationCard: 20,
            bottomNavigationFlagBackground: 7,

            // Example Poll Card //
            examplePollCard: 10,

            // Input //
            cta: 8,

            // CTAs //
            input: 8,

            // Option Card //
            optionCard: 13,

            // Poll Default Settings Card //
            profileSettingsCard: 8,

            // Modal //
            modal: 20,

            // Drawer //
            drawer: 40,

            // Poll Send Settings //
            pollSettingsCard: 8,

            // Verify Phone Number //
            phoneNumberCardBackground: 8,
            verificationCodeCardBackground: 8,
        },
        // to be removed
        borderRadius: {
            button: 8,
            modal: 20,
            errorModal: 8,
            bottomNav: 21,
            CTA: 10,
            pills: 50,
            pollSendSetting: 8,
            drawer: 40,
            input: 8,
        },
        //
        image: {
            // App //
            appLogo: require("@assets/images/Icon-1.png"),
            previewImageBackground: require("@assets/images/preview-image/white-background.png"),
            previewImageCard: require("@assets/images/preview-image/card.png"),
            appBackgroundDesktop: "linear-gradient(180deg, #84CFFF, #4DA1FF)",
            appBackgroundMobile: "#fff",
            closeX: Icons.dismiss,
            linkOut: "default/linkOut.svg",
            splashImage: require("@assets/images/splash.png"),
            homeScreenImage: require("@assets/images/homescreen6x.png"),
            consentScreenImage: require("@assets/images/consent.png"),
            backIcon: Icons.back,
            checkboxChecked: Icons.checkboxChecked,
            checkboxUnchecked: Icons.checkboxUnchecked,
            pollHistoryHeader: require("@assets/images/PollHistoryIcon.png"),
            pollHistoryEmpty: require("@assets/images/Polygon.png"),
            imagePlaceholder: EncodedImages.imagePlaceholder,

            // Bottom Navigation //
            navBarTrash: Icons.discard,
            navBarProfile: Icons.profile,
            navBarEdit: Icons.edit,
            navBarHome: Icons.home,
            navBarShare: Icons.share,
            navBarInfo: Icons.info,

            // Example Poll Card //
            examplePollSuggestionButton:
                "default/examplePollSuggestionButton.svg",
            examplePollAddButton: "default/examplePollAddButton.svg",
            examplePollDateAndTime: "default/examplePollDateAndTime.svg",

            // Option Card //
            optionCardVoteCounterActive:
                "default/optionCardVoteCounterActive.svg",
            optionCardVoteCounterInactive:
                "default/optionCardVoteCounterInactive.svg",
            optionCardLocked: Icons.optionLocked,
            optionCardRemove: Icons.optionRemove,
            optionCardLink: "default/optionCardLink.svg",
            optionCardAddedConfirmation:
                "default/optionCardAddedConfirmation.svg",
            optionCardCancel: "default/optionCardCancel.svg",
            optionCardDrag: Icons.group,

            // Profile //
            userNameEdit: Icons.edit,
            profileAvatar: Icons.profile,
            profileAuthenticateButtonIcon: Icons.phone,
            profilePhoneNumberIcon: Icons.phone,
            profileLinkOutIcon: Icons.linkOut,
            profileShareFeedbackIcon: Icons.share,
            userSettingsEditPoll: Icons.lock,
            userSettingsMultiVote: Icons.checkDouble,
            userSettingsSeeWhoVoted: Icons.eye,

            // Modal/Drawer //
            pollSendSettingsGraphic: EncodedImages.controls,
            pollLockedGraphic: EncodedImages.locked,
            addUserNameGraphic: EncodedImages.helloRafiki,
            loadPollFailedGraphic: EncodedImages.internetConnectionError,
            launchPollFailedGraphic: EncodedImages.internetConnectionError,
            updatePollFailedGraphic: EncodedImages.internetConnectionError,
            updatePollCrossErrorGraphic: EncodedImages.crossError,
            missingOptionsGraphic: EncodedImages.building,
            pollAnonymousGraphic: "default/pollAnonymousGraphic.svg",
            shareVoteGraphic: EncodedImages.voteShare,
            userAuthenticateGraphic: EncodedImages.phoneVerification,
            pollsAdvertise: EncodedImages.pollsAdvertise,
            rememberSubmit: EncodedImages.rememberSubmit,
            rememberSubmitBackground: EncodedImages.trapezeModalBackground,
            postVotePromo: null,

            // Share Icons //
            shareViaSMS: require("@assets/images/sms.png"),
            shareViaEmail: require("@assets/images/Email.png"),
            shareViaMessenger: require("@assets/images/messanger.png"),
            shareViaWhatsApp: require("@assets/images/Whatsapp.png"),
            shareViaIMessages: require("@assets/images/iMessage.png"),
            shareCopyButtonCheckmark: Icons.checkSingle,

            // Get App Screen
            getAppScreenImage: EncodedImages.phoneVerification,
        },

        layout: {
            palette: [
                "#008DC3",
                "#2CAFD9",
                "#42C0E4",
                "#3FCDE4",
                "#26C9D9",
                "#21ABA4",
                "#29D4B6",
                "#1C575F",
                "#023E8A",
                "#0077B6",
            ],
            padding: {
                phoneButton: 55,
                cta: {
                    padding: 10,
                },
            },
            margin: {
                sectionTitle: {
                    top: 20,
                    bottom: 10,
                },
            },

            appCardDesktopRadius: 41,
            appCardDesktopWidth: 576,
            appCardMobileWidth: 320,
            defaultOptionCardHeight: 62,
            profileAvatarCircle: 95,
            smallProfileAvatarCircle: 35,
            modalOverlayOpacity: 0.75,
        },
        lottie: {
            checkmark: require("@assets/lottie/checkmark.json"),
            checkmarkDone: require("@assets/lottie/checkmark_green.json"),
            checkBox: require("@assets/lottie/checkBox.json"),
            whiteSpinner: require("@assets/lottie/WhiteLoadingSpinner.json"),
            spinner: require("@assets/lottie/spinner.json"),
            trashCan: require("@assets/lottie/trashCan.json"),
            createPollSuccess: require("@assets/lottie/createPollSuccess.json"),
            signInSuccess: require("@assets/lottie/signInSuccess.json"),
            voteSuccess: require("@assets/lottie/voteSuccess.json"),
            success: require("@assets/lottie/voteSuccess.json"),
        },
    }

    // apply the overrides on top of the generated theme

    return {
        color: {
            ...theme.color,
            ...overrides.color,
        },
        font: {
            ...theme.font,
            ...overrides.font,
        },
        shadow: {
            ...theme.shadow,
            ...overrides.shadow,
        },
        cornerRadius: {
            ...theme.cornerRadius,
            ...overrides.cornerRadius,
        },
        image: {
            ...theme.image,
            ...overrides.image,
        },
        layout: {
            ...theme.layout,
            ...overrides.layout,
        },
        lottie: {
            ...theme.lottie,
            ...overrides.lottie,
        },

        //old stuff to be removed
        borderRadius: {
            ...theme.borderRadius,
            ...overrides.borderRadius,
        },
        fontFiles: {
            ...theme.fontFiles,
            ...overrides.fontFiles,
        },
    }
}

const hexToRgbA = (hex: string, alpha: number) => {
    let c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("")
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = "0x" + c.join("")
        const n = Number(c)
        const rgba = `rgba(${[(n >> 16) & 255, (n >> 8) & 255, n & 255].join(
            ",",
        )},${alpha})`
        // console.log("RGBA SHADOW: ", rgba)
        return rgba
    }
    return "rgba(0,0,0,0)"
}

// create the default theme

const fontPalette: FontPalette = {
    regular: "sf-pro-display",
    semiBold: "sf-pro-display-semibold",
    bold: "sf-pro-display-bold",

    pollsRegular: "sf-pro-display",
    pollsSemiBold: "sf-pro-display-semibold",
    pollsBold: "sf-pro-display-bold",
}

const fontFiles: FontFiles = {
    // sf pro font files are always included
    IcoMoon: require("@assets/icons/icomoon.ttf"),
}

export const DefaultDarkTheme: Theme = createTheme({
    fontPalette: fontPalette,
    fontFiles: fontFiles,
    colorPalette: defaultDarkColorPalette,
    fontSizeMultiplier: 1,
    lineHeightMultiplier: 1,
    overrides: {
        // color
        // font
        // shadow
        // cornerRadius
        image: {
            appBackgroundDesktop: "linear-gradient(180deg, #222, #444)",
            previewImageCard: require("@assets/images/preview-image/cardDark.png"),
            previewImageBackground: require("@assets/images/preview-image/dark-background.png"),
        },
        // layout
        // lottie
        //old stuff to be removed
        // borderRadius
        // fontFiles
    },
})

export const DefaultTheme: Theme = createTheme({
    fontPalette: fontPalette,
    fontFiles: fontFiles,
    colorPalette: defaultColorPalette,
    fontSizeMultiplier: 1,
    lineHeightMultiplier: 1,
    overrides: {
        // color
        // font
        // shadow
        // cornerRadius
        // image
        // layout
        // lottie
        //old stuff to be removed
        // borderRadius
        // fontFiles
    },
})

export const TenantTheme = DefaultTheme

export const getThemeByTenantName = (tenantName: string): Theme => {
    return TenantTheme
}
