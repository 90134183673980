import { View } from "react-native"
import React from "react"
import { ListSkeletonItem } from "./ListSkeletonItem"
import { LottieAnimation } from "@components"
import useStyles from "@helpers/hooks/useStyles"
import { ListSkeletonStyles } from "./ListSkeleton.styles"
import SkeletonLoader from "expo-skeleton-loader"

const ListSkeleton = () => {
    const { styles, theme } = useStyles(ListSkeletonStyles)

    const skeletonList = [
        {
            checked: true,
        },
        {
            checked: false,
        },
    ]

    return (
        <View style={styles.container}>
            <SkeletonLoader
                boneColor={theme.color.skeletonDark}
                highlightColor={theme.color.skeletonHighlight}
                duration={800}
            >
                <SkeletonLoader.Container style={styles.contentContainer}>
                    <SkeletonLoader.Item
                        style={{
                            width: 300,
                            height: 13,
                            borderRadius: 6.5,
                            marginBottom: 15,
                        }}
                    ></SkeletonLoader.Item>
                </SkeletonLoader.Container>
            </SkeletonLoader>
            {skeletonList.map((item, index) => (
                <ListSkeletonItem key={index} checked={item.checked} />
            ))}
            <LottieAnimation
                source={theme.lottie.spinner}
                style={styles.spinner}
                autoPlay
                loop
            />
        </View>
    )
}

export default ListSkeleton
