import { ScreenName } from "@screens"
import { getAppConfig } from "@services/appConfig"
import Logger from "@services/logger"
import { PollData, StorageKeys } from "@types"
import * as FirebaseAnalytics from "expo-firebase-analytics"
import * as Manifest from "@helpers/manifest"
import AmplitudeService from "@AmplitudeService/AmplitudeService"
import * as AmplitudeTypes from "./amplitude/AmplitudeTypes"
import { Platform } from "react-native"
import {
    airbridgeAnalyticsConfig,
    amplitudeAnalyticsConfig,
    AnalyticsConfig,
    firebaseAnalyticsConfig,
} from "./AnalyticsConfigs"
import {
    Parameters,
    ProcessedEvent,
    ProcessedEventAndParameters,
    ProcessedParameter,
    ProcessedParameters,
    ProcessedUserProperties,
    ProcessedUserProperty,
    UserProperties,
} from "./AnalyticsTypes"
import { AnalyticsEvent, Parameter, UserProperty } from "./AnalyticsEvents"
import AirbridgeService from "@AirbridgeService/AirbridgeService"
import { snakeCase } from "change-case"
import { storageService } from "@services/storage"
import uuid from "react-native-uuid"
import {
    EmbeddedMessageEvent,
    sendWrapperMessage,
} from "@helpers/sendWrapperMessage"
import { EmbeddedState } from "@contexts/AppProvider/AppReducer"

// exports

export {
    UserProperty,
    AnalyticsEvent,
    Parameter,
    initialize,
    logout,
    setUnauthenticatedUserIdentity,
    setAuthenticatedUserIdentity,
    setUserName,
    setCurrentScreen,
    setEmbeddedState,
    logEventWithPoll,
    logEvent,
    forceUploadEvents,
    getAnalyticsDeviceId,
}

// check if we should log

const shouldLog =
    getAppConfig().analytics.shouldLog && // certain environments have logging disabled
    AmplitudeService && // native modules are not available in Expo Go
    AirbridgeService

const analyticsLog = (message: string, parameters?: any) => {
    Logger.analytics(
        `ANALYTICS: ${message} ${parameters ? JSON.stringify(parameters) : ""}`,
    )
}

let analyticsDeviceId: string | undefined = undefined

const getAnalyticsDeviceId = () => {
    return analyticsDeviceId
}

const initialize = () => {
    analyticsLog("initializing analytics")

    // logout should return without delay,
    // so we will call an async helper without awaiting it
    const initializationHelper = async () => {
        if (shouldLog) {
            // get app version

            const appVersion =
                Platform.select({
                    native: Manifest.appVersion(),
                    web: Manifest.jsVersion(),
                }) ?? "##"

            // get custom device id for web and for native fallback
            let customAnalyticsDeviceId: string
            const deviceIdFromStorage = await storageService.getItem<
                string | null
            >(StorageKeys.ANALYTICS_DEVICE_ID)
            if (deviceIdFromStorage) {
                customAnalyticsDeviceId = deviceIdFromStorage
            } else {
                customAnalyticsDeviceId = uuid.v4().toString()
                await storageService.setItem(
                    StorageKeys.ANALYTICS_DEVICE_ID,
                    customAnalyticsDeviceId,
                )
            }

            // firebase
            // nothing to do

            // amplitude
            // test project api key: 61c67d67c77e983fd4256c83fac711a9
            // prod project api key: 414cdaf28cef2ac69513e265483ea1f8

            const amplitudeApiKey = "414cdaf28cef2ac69513e265483ea1f8"
            const amplitudeLogLevel = AmplitudeTypes.LogLevel.Verbose

            // airbridge
            // test project name: pollstest
            // test web token: bfe32ff16f2a49218bd1a48e64423226

            // prod project name: polls
            // prod web token: 6ce66bd53d764775b558e4b110ac85ce
            const airbridgeAppName = "polls"
            const airbridgeWebToken = "6ce66bd53d764775b558e4b110ac85ce"

            const isNative = Platform.select({ native: true, default: false })

            if (isNative) {
                // native

                // amplitude

                await AmplitudeService?.init({
                    apiKey: amplitudeApiKey,
                    appVersion: appVersion,
                    logLevel: amplitudeLogLevel,
                })

                // get analytics device id

                let nativeAnalyticsDeviceId: string

                const amplitudeDeviceId = await AmplitudeService?.getDeviceId()

                if (amplitudeDeviceId) {
                    nativeAnalyticsDeviceId = amplitudeDeviceId
                    analyticsLog(
                        `Got Amplitude Device ID: ${amplitudeDeviceId}`,
                    )
                } else {
                    analyticsLog(
                        "failed to get Amplitude Device ID, using backup",
                    )
                    nativeAnalyticsDeviceId = customAnalyticsDeviceId
                    AmplitudeService?.setDeviceId(nativeAnalyticsDeviceId)
                }

                analyticsLog(`analyticsDeviceId: ${nativeAnalyticsDeviceId}`)
                
                // store
                analyticsDeviceId = nativeAnalyticsDeviceId

                // airbridge

                AirbridgeService?.init({
                    appName: airbridgeAppName,
                    webToken: airbridgeWebToken,
                    deviceId: nativeAnalyticsDeviceId,
                })
            } else {
                // web

                // airbridge

                AirbridgeService?.init({
                    appName: airbridgeAppName,
                    webToken: airbridgeWebToken,
                    deviceId: customAnalyticsDeviceId,
                })

                // amplitude

                AmplitudeService?.init({
                    apiKey: amplitudeApiKey,
                    appVersion: appVersion,
                    logLevel: amplitudeLogLevel,
                    deviceId: customAnalyticsDeviceId,
                })

                // store
                analyticsDeviceId = customAnalyticsDeviceId

                // send to wrapper

                sendWrapperMessage({
                    event: EmbeddedMessageEvent.IDENTITY,
                    payload: {
                        amplitudeDeviceId: customAnalyticsDeviceId,
                    },
                })
            }
        } else {
            analyticsLog("analytics logging disabled via config")
        }

        // call update user to set user properties like js version
        updateUserProperties({})
    }

    // run without awaiting
    initializationHelper()
}

const logout = () => {
    analyticsLog("logging out")

    // logout should return without delay,
    // so we will call an async helper without awaiting it
    const logoutHelper = async () => {
        if (shouldLog) {
            // firebase
            // nothing to do

            // amplitude
            AmplitudeService?.logout()

            // airbridge
            AirbridgeService?.logout()
        }
    }

    // run without awaiting
    logoutHelper()
}

const processUserProperties = (
    analyticsConfig: AnalyticsConfig,
    userProperties: UserProperties,
): ProcessedUserProperties => {
    return processObject(
        userProperties,
        analyticsConfig.maxUserPropertyCount,
        analyticsConfig.maxUserPropertyKeyLength,
        analyticsConfig.maxUserPropertyValueLength,
        analyticsConfig.convertUserPropertyToString,
        analyticsConfig.convertUserPropertyKeyToSnake,
    )
}

const setUnauthenticatedUserIdentity = (deviceId: string) => {
    analyticsLog(`set unauthenticated user identity, deviceId: ${deviceId}`)

    if (shouldLog) {
        // special case for device id

        // firebase
        // nothing to do, handled by user properties only

        // amplitude
        // we are using airbridge device id for amplitude device ID

        // airbridge
        // we are using default airbridge device id

        // then set user properties

        updateUserProperties({ pollsDeviceId: deviceId })
    }

    sendWrapperMessage({
        event: EmbeddedMessageEvent.IDENTITY,
        payload: {
            pollsDeviceId: deviceId,
        },
    })
}

const setAuthenticatedUserIdentity = (deviceId: string, userId: string) => {
    analyticsLog(`set authenticated user identity, userId: ${userId}`)

    if (shouldLog) {
        // special case for device id and user id

        // firebase
        // nothing to do, handled by user properties only

        // amplitude
        AmplitudeService?.setUserId(userId)

        // airbridge
        AirbridgeService?.setUserId(userId)

        // then set user properties

        updateUserProperties({
            pollsDeviceId: deviceId,
            pollsUserId: userId,
        })
    }

    sendWrapperMessage({
        event: EmbeddedMessageEvent.IDENTITY,
        payload: {
            pollsDeviceId: deviceId,
            pollsUserId: userId,
        },
    })
}

const setUserName = (userName?: string) => {
    analyticsLog(`updating user name, user name: ${userName}`)

    if (shouldLog) {
        // set user properties

        updateUserProperties({ userName: userName })
    }
}

const setEmbeddedState = (embeddedState: EmbeddedState) => {
    analyticsLog(`updating embedded state: ${embeddedState}`)

    if (shouldLog) updateUserProperties({ embeddedState })
}

const updateUserProperties = (userProperties: UserProperties) => {
    // merge in properties that we always have

    const mergedUserProperties: UserProperties = {
        ...userProperties,
        appVersion: Manifest.appVersion(),
        runtimeVersion: Manifest.runtimeVersion(),
        jsVersion: Manifest.jsVersion(),
        stage: Manifest.appEnv(),
        tenant: getAppConfig().tenantConfig.analyticsTenantName,
    }

    analyticsLog("updating user properties", mergedUserProperties)

    if (shouldLog) {
        // firebase
        const firebaseUserProperties = processUserProperties(
            firebaseAnalyticsConfig,
            mergedUserProperties,
        )
        FirebaseAnalytics.setUserProperties(
            firebaseUserProperties as {
                [key in ProcessedUserProperty]: string
            },
        )

        // amplitude
        const amplitudeUserProperties = processUserProperties(
            amplitudeAnalyticsConfig,
            mergedUserProperties,
        )
        AmplitudeService?.setUserProperties(amplitudeUserProperties)

        // airbridge
        const airbridgeUserProperties = processUserProperties(
            airbridgeAnalyticsConfig,
            mergedUserProperties,
        )
        AirbridgeService?.setUserProperties(airbridgeUserProperties)
    }
}

const setCurrentScreen = (routeName: string, pollData: PollData) => {
    let analyticsScreenName: string | undefined

    // gives us a chance to override if we want a prettier name for analytics

    switch (routeName) {
        case ScreenName.PROFILE_SCREEN:
            analyticsScreenName = "profile"
            break
        case ScreenName.VOTE_SCREEN:
            analyticsScreenName = "vote"
            break
        case ScreenName.CREATE_POLL_SCREEN:
            analyticsScreenName = "createPoll"
            break
        case ScreenName.THEME_SCREEN:
            analyticsScreenName = "theme"
            break
        case ScreenName.CONSENT_SCREEN:
            analyticsScreenName = "agree"
            break
        case ScreenName.HOME_SCREEN:
            analyticsScreenName = "home"
            break
        case "HomeStack":
            analyticsScreenName = "home"
            break
        case ScreenName.SIGN_IN_SCREEN:
            analyticsScreenName = "signIn"
            break
        case ScreenName.SIGN_IN_SCREEN_CONFIRM:
            analyticsScreenName = "verifyCode"
            break
        default:
            analyticsScreenName = routeName
            analyticsLog("screen name unrecognized:", routeName)
            break
    }

    analyticsLog(`SCREEN VIEW: ${analyticsScreenName}`)

    if (shouldLog && analyticsScreenName) {
        FirebaseAnalytics.logEvent("screen_view", {
            screen_name: analyticsScreenName,
        })
        const screenParameters: Parameters = {
            screenName: analyticsScreenName,
        }
        if (pollData) {
            logEventWithPoll(
                AnalyticsEvent.viewScreen,
                pollData,
                screenParameters,
            )
        } else {
            logEvent(AnalyticsEvent.viewScreen, screenParameters)
        }
    }
}

// TODO: Discuss aggregates, discuss new parameters:
//  - number of options
//  - number of votes
//  - number of unique voters
//  - has user votes
//  - is user owner
const parametersForPoll = (poll: PollData) => {
    const parameters: Parameters = {}

    parameters[Parameter.pollId] = poll.id
    parameters[Parameter.title] = poll.title
    parameters[Parameter.version] = poll.version
    parameters[Parameter.minCompatibleVersion] = poll.minCompatibleVersion
    parameters[Parameter.ownerId] = poll.ownerId
    parameters[Parameter.timeZoneId] = poll.timeZoneId

    parameters[Parameter.declareWinner] = poll.settings.declareWinner
        ? "true"
        : "false"
    parameters[Parameter.editPoll] = poll.settings.editPoll ? "true" : "false"
    parameters[Parameter.seeWhoVoted] = poll.settings.seeWhoVoted
        ? "true"
        : "false"
    parameters[Parameter.multipleVotes] = poll.settings.multipleVotes
        ? "true"
        : "false"
    parameters[Parameter.sendType] = poll.settings.sendType

    const postVoteAction = poll.settings.postVoteAction
    if (postVoteAction) {
        parameters[Parameter.postVoteAction] = postVoteAction
    }

    // should we add any aggregates?
    // number of options
    // number of votes
    // number of unique voters
    // has user voted
    // is user owner

    return parameters
}

const logEventWithPoll = (
    event: AnalyticsEvent,
    poll: PollData,
    parameters?: Parameters,
) => {
    const allParameters = {
        ...parametersForPoll(poll),
        ...parameters,
    }
    logEvent(event, allParameters)
}

// Keynotes:
//  - firebase - nothing we can do
//  - airbridge - events are uploaded immediately
const forceUploadEvents = () => {
    analyticsLog("force upload events")

    if (shouldLog) {
        // firebase
        // nothing we can do

        // amplitude
        AmplitudeService?.forceUploadEvents()

        // airbridge
        // events are uploaded immediately
    }
}

const processEvent = (
    analyticsConfig: AnalyticsConfig,
    event: AnalyticsEvent,
    parameters?: Parameters,
): ProcessedEventAndParameters => {
    // process event name
    let processedEvent: ProcessedEvent = event
    if (analyticsConfig.convertEventNameToSnake) {
        processedEvent = snakeCase(processedEvent)
    }
    processedEvent = processedEvent.substring(
        0,
        analyticsConfig.maxEventNameLength,
    )

    // process event parameters
    let processedParameters: ProcessedParameters | undefined = parameters

    if (parameters) {
        // enforce max number of parameters, max key length, max value length
        processedParameters = processObject(
            parameters,
            analyticsConfig.maxParameterCount,
            analyticsConfig.maxParameterLength,
            analyticsConfig.maxValueLength,
            analyticsConfig.convertParameterToString,
            false,
        )
    }

    return {
        processedEvent: processedEvent,
        processedParameters: processedParameters,
    }
}

const processObject = (
    object: { [key in string]: string | boolean | number | undefined },
    maxKeyCount: number,
    maxKeyLength: number,
    maxValueLength: number,
    convertValueToString: boolean,
    convertKeyToSnake: boolean,
): { [key in string]: string | boolean | number } => {
    return Object.keys(object)
        .slice(0, maxKeyCount) // truncate number of parameters
        .reduce(function (result, rawKey) {
            // truncate key
            const key = rawKey as unknown as Parameter
            let processedKey: string = key
            if (convertKeyToSnake) {
                processedKey = snakeCase(processedKey)
            }
            processedKey = processedKey.substring(0, maxKeyLength)
            const value = object[key]
            let processedValue = value
            if (convertValueToString) {
                processedValue = processedValue?.toString()
            }
            if (typeof processedValue === "string") {
                // truncate value
                processedValue = processedValue.substring(0, maxValueLength)
            }
            if (processedValue) {
                result[processedKey] = processedValue
            }
            return result
        }, {} as { [key in string]: string | boolean | number })
}

const logEvent = (event: AnalyticsEvent, parameters?: Parameters) => {
    analyticsLog(`EVENT: ${event}`, parameters)

    if (shouldLog) {
        // firebase
        const firebaseEventAndParameters = processEvent(
            firebaseAnalyticsConfig,
            event,
            parameters,
        )
        FirebaseAnalytics.logEvent(
            firebaseEventAndParameters.processedEvent,
            firebaseEventAndParameters.processedParameters,
        )

        // amplitude
        const amplitudeEventAndParameters = processEvent(
            amplitudeAnalyticsConfig,
            event,
            parameters,
        )
        AmplitudeService?.logEvent(
            amplitudeEventAndParameters.processedEvent,
            amplitudeEventAndParameters.processedParameters,
        )

        // airbridge
        const airbridgeEventAndParameters = processEvent(
            airbridgeAnalyticsConfig,
            event,
            parameters,
        )
        AirbridgeService?.logEvent(
            airbridgeEventAndParameters.processedEvent,
            airbridgeEventAndParameters.processedParameters as {
                [key in ProcessedParameter]: string
            },
        )

        postProcessEvent(event, parameters)
    }

    // send wrapper message even if shouldLog is false
    // (wrapper can choose whether or not to forward it to analytics services)
    sendWrapperMessage({
        event: EmbeddedMessageEvent.ANALYTICS_EVENT,
        payload: {
            event,
            parameters,
        },
    })
}

const postProcessEvent = (event: AnalyticsEvent, parameters?: Parameters) => {
    // for some events, in addition to logging the event we have some
    // post processing actions

    switch (event) {
        case AnalyticsEvent.createPollSuccess: {
            // increment number of polls launched
            AmplitudeService?.incrementUserProperty(
                UserProperty.pollSendCount,
                1,
            )
            break
        }
        case AnalyticsEvent.voteSuccess: {
            // increment number of times voted
            AmplitudeService?.incrementUserProperty(
                UserProperty.submitVoteCount,
            )
            break
        }
    }
}
